@import '@componentsStyles/colors.scss';
@import '@componentsStyles/elevation.scss';
@import '@componentsStyles/variables.scss';
@import '@componentsStyles/breakpoints.scss';
@import '@componentsStyles/mixins.scss';

.header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  transition: $transition-duration $transition-timing-function;
}

.grid {
  display: grid;
  gap: 20px;

  &-omni {
    grid-template-columns: 14fr 110px 17fr 90px 100px 80px;
  }

  &-admin {
    grid-template-columns: 1fr 110px 90px 80px;
  }
}

@container (min-width: 700px) {
  .grid-admin {
    grid-template-columns: 2fr 1fr 1fr 1fr;
  }
}

@container (min-width: 880px) {
  .grid {
    gap: 40px;
  }
}

@container (min-width: 950px) {
  .grid-omni {
    grid-template-columns: 14fr 11fr 17fr 9fr 10fr 9fr;
  }
}

@container (min-width: 1200px) {
  .grid {
    gap: 80px;
  }
}

.right {
  display: flex;
  justify-self: flex-end;
  text-align: right;
}

.row {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.details {
  position: absolute;
  top: 24px;
  right: 12px;

  path {
    transition: $transition-duration $transition-timing-function;
    fill: $gray-40;
  }
}

.card {
  position: relative;
  padding: 20px 40px 0 40px;

  &:hover {
    cursor: pointer;
    .header {
      color: $active-side-menu-item;
    }
    path {
      fill: $gray-100;
    }
  }

  &--loading {
    position: relative;
    padding: 20px 40px 0 40px;

    path {
      fill: $gray-20;
    }
  }
}

.scroll-wrapper {
  overflow-x: hidden;
}

.scroll-container {
  overflow-x: auto;
  padding-bottom: 20px;
  @include scrollbar-transparent();
  container-type: inline-size;
}

.min-width-omni {
  min-width: 770px;
}

.min-width-admin {
  min-width: 500px;
}
