@import '@componentsStyles/colors.scss';
@import '@componentsStyles/breakpoints.scss';
@import '@componentsStyles/variables.scss';
@import '@componentsStyles/zIndex.scss';

.wrapper {
  background: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(70px);
          backdrop-filter: blur(70px);
  z-index: $dropdown-zIndex;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  margin: 0 20px;
}

.main {
  display: flex;
  align-items: center;
}

.switcher {
  margin-right: 20px;

  svg {
    transition: $transition-duration $transition-timing-function;
    fill: $gray-60;
  }

  &:hover {
    cursor: pointer;
    svg {
      fill: $gray-100;
    }
  }
}

.icon {
  path {
    fill: $gray-60;
  }
  &--active {
    path {
      fill: $gray-100;
    }
  }
  &--disabled {
    path {
      fill: $gray-20;
    }
  }
}

.logo {
  &:active {
    filter: grayscale(1);
  }
}

.links {
  margin: 0 44px;
  display: none;
  @media (min-width: $md) {
    display: flex;
    align-items: center;
  }
}

.link {
  margin: 0 16px;
  transition: $transition-duration $transition-timing-function;
  color: $gray-100;
  text-decoration: none;
  cursor: pointer;

  path {
    transition: $transition-duration $transition-timing-function;
  }

  &:hover,
  &:active {
    path {
      fill: $active-side-menu-item;
    }
    color: $active-side-menu-item;
  }
}

.avatar-block {
  display: none;

  @media (min-width: $md) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    transition: $transition-duration $transition-timing-function;
    cursor: pointer;

    &:hover {
      background: $background-hover;
      border-radius: $border-radius;
    }
  }
}

.menu {
  &-tablet {
    display: block;
    position: absolute;
    right: 20px;
    margin-top: 12px;
    @media (min-width: $md) {
      display: none;
    }
  }
  &-desktop {
    display: none;
    @media (min-width: $md) {
      display: block;
      position: absolute;
      right: 20px;
    }
  }

  &-block {
    position: relative;

    &--tablet {
      display: block;
      @media (min-width: $md) {
        display: none;
      }
    }

    &--desktop {
      display: none;
      @media (min-width: $md) {
        display: block;
      }
    }
  }
}

.name {
  margin-left: 8px;
  margin-right: 2px;
}
