.rc-tabs {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.rc-tabs-bar,
.rc-tabs-nav-container {
  font-size: 14px;
  line-height: 1.5;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  outline: none;
  zoom: 1;
  transition: padding 0.45s;
}

.rc-tabs-ink-bar {
  z-index: 1;
  position: absolute;
  box-sizing: border-box;
  margin-top: -3px;
  background-color: black;
  transform-origin: 0 0;
  width: 0;
  height: 0;
}

.rc-tabs-ink-bar-animated {
  transition:
    transform 0.3s cubic-bezier(0.35, 0, 0.25, 1),
    left 0.3s cubic-bezier(0.35, 0, 0.25, 1),
    top 0.3s cubic-bezier(0.35, 0, 0.25, 1),
    height 0.3s cubic-bezier(0.35, 0, 0.25, 1),
    width 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}

.rc-tabs-tab-prev,
.rc-tabs-tab-next {
  user-select: none;
  z-index: 1;
  line-height: 36px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  position: absolute;
}

.rc-tabs-tab-prev-icon,
.rc-tabs-tab-next-icon {
  position: relative;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: inherit;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  font-smoothing: antialiased;
  text-stroke-width: 0;
  font-family: sans-serif;
}

.rc-tabs-tab-prev-icon:before,
.rc-tabs-tab-next-icon:before {
  display: block;
}

.rc-tabs-tab-btn-disabled {
  cursor: default;

  div {
    svg {
      fill: #dadada;
    }
  }
}

.rc-tabs-nav-wrap {
  overflow: hidden;
}

.rc-tabs-nav {
  box-sizing: border-box;
  padding-left: 0;
  position: relative;
  margin-bottom: 0;
  float: left;
  list-style: none;
  display: inline-block;
  transform-origin: 0 0;
}

.rc-tabs-nav-container:has(.rc-tabs-tab-prev.rc-tabs-tab-btn-disabled)
  .rc-tabs-nav-wrap::before {
  height: 0;
}

.rc-tabs-nav-container:has(.rc-tabs-tab-next.rc-tabs-tab-btn-disabled)
  .rc-tabs-nav-wrap::after {
  height: 0;
}

.rc-tabs-nav-wrap::before {
  position: absolute;
  content: '';
  pointer-events: none;
  left: 40px;
  width: 60px;
  height: 60px;
  background: linear-gradient(270deg, rgba(249, 251, 255, 0), #fff);
  z-index: 10;
}

.rc-tabs-nav-wrap::after {
  position: absolute;
  content: '';
  pointer-events: none;
  right: 40px;
  height: 60px;
  width: 60px;
  background: linear-gradient(90deg, rgba(249, 251, 255, 0), #fff);
  z-index: 10;
}

.rc-tabs-nav-animated {
  transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1);

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.rc-tabs-nav:before,
.rc-tabs-nav:after {
  display: table;
  content: ' ';
}

.rc-tabs-nav:after {
  clear: both;
}

.rc-tabs-rtl .rc-tabs-nav {
  float: right;
}

.rc-tabs-tab {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  padding: 8px 16px;
  color: #8f8f8f;
  margin: 0;
  display: flex;
  outline: none;
  cursor: pointer;
  position: relative;
  align-items: center;

  &:hover:after {
    width: 100%;
    left: 0;
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    display: block;
    content: '';
    background: black;
    transition:
      width 0.3s ease 0s,
      left 0.3s ease 0s;
  }
}

.rc-tabs-tab-active,
.rc-tabs-tab-active:hover {
  color: black;
  cursor: default;
  transform: translateZ(0);
}

.rc-tabs-tab-disabled {
  cursor: default;
  color: #dadada;
}

.rc-tabs-tab-disabled:hover {
  &:hover:after {
    width: 0 !important;
    left: 0 !important;
  }
}

.rc-tabs-content {
  zoom: 1;
}

.rc-tabs-content .rc-tabs-tabpane {
  overflow: auto;
}

.rc-tabs-content-animated {
  display: flex;
  will-change: transform;
}

.rc-tabs-content-animated .rc-tabs-tabpane {
  flex-shrink: 0;
}

.no-flexbox .rc-tabs-content {
  transform: none !important;
  overflow: auto;
}

.no-csstransitions .rc-tabs-tabpane-inactive,
.no-flexbox .rc-tabs-tabpane-inactive,
.rc-tabs-content-no-animated .rc-tabs-tabpane-inactive {
  display: none;
}

.rc-tabs-rtl {
  direction: rtl;
}

.rc-tabs-left {
  border-right: 2px solid #f3f3f3;
}

.rc-tabs-left .rc-tabs-bar {
  float: left;
  height: 100%;
  margin-right: 10px;
  border-right: 1px solid #f3f3f3;
}

.rc-tabs-left .rc-tabs-nav-container {
  height: 100%;
}

.rc-tabs-left .rc-tabs-nav-container-scrolling {
  padding-top: 32px;
  padding-bottom: 32px;
}

.rc-tabs-left .rc-tabs-nav-wrap {
  height: 100%;
}

.rc-tabs-left .rc-tabs-content-animated {
  flex-direction: column;
}

.rc-tabs-left .rc-tabs-content-animated .rc-tabs-tabpane {
  height: 100%;
}

.rc-tabs-left .rc-tabs-nav-scroll {
  height: 99999px;
}

.rc-tabs-left .rc-tabs-nav-swipe {
  position: relative;
  top: 0;
}

.rc-tabs-left .rc-tabs-nav-swipe .rc-tabs-nav {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.rc-tabs-left .rc-tabs-nav-swipe .rc-tabs-nav .rc-tabs-tab {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
}

.rc-tabs-left .rc-tabs-tab-prev,
.rc-tabs-left .rc-tabs-tab-next {
  margin-top: -2px;
  height: 0;
  line-height: 32px;
  width: 0;
  display: block;
  text-align: center;
  opacity: 0;
  transition:
    width 0.3s,
    height 0.3s,
    opacity 0.3s;
}

.rc-tabs-top .rc-tabs-tab-arrow-show,
.rc-tabs-left .rc-tabs-tab-arrow-show,
.rc-tabs-bottom .rc-tabs-tab-arrow-show,
.rc-tabs-right .rc-tabs-tab-arrow-show {
  opacity: 1;
  width: 100%;
  height: 32px;
}

.rc-tabs-left .rc-tabs-tab-next {
  bottom: 0;
}

.rc-tabs-left .rc-tabs-tab-next-icon {
  transform: rotate(90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  background: linear-gradient(90deg, rgba(249, 251, 255, 0), #fff);
}

.rc-tabs-left .rc-tabs-tab-next-icon:before {
  content: '>';
  background: linear-gradient(90deg, rgba(249, 251, 255, 0), #fff);
}

.rc-tabs-left .rc-tabs-tab-prev {
  top: 2px;
}

.rc-tabs-left .rc-tabs-tab-prev-icon {
  transform: rotate(270deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.rc-tabs-left .rc-tabs-tab-prev-icon:before {
  content: '>';
  background: linear-gradient(90deg, rgba(249, 251, 255, 0), #fff);
}

.rc-tabs-left .rc-tabs-ink-bar {
  width: 2px;
  right: 0;
  top: 0;
}

.rc-tabs-left .rc-tabs-tab {
  padding: 16px 24px;
}

.rc-tabs-right {
  border-left: 2px solid #f3f3f3;
}

.rc-tabs-right .rc-tabs-bar {
  float: right;
  height: 100%;
  margin-left: 10px;
  border-left: 1px solid #f3f3f3;
}

.rc-tabs-right .rc-tabs-nav-container {
  height: 100%;
}

.rc-tabs-right .rc-tabs-nav-container-scrolling {
  padding-top: 32px;
  padding-bottom: 32px;
}

.rc-tabs-right .rc-tabs-nav-wrap {
  height: 100%;
}

.rc-tabs-right .rc-tabs-nav-scroll {
  height: 99999px;
}

.rc-tabs-right .rc-tabs-nav-swipe {
  position: relative;
}

.rc-tabs-right .rc-tabs-nav-swipe .rc-tabs-nav {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.rc-tabs-right .rc-tabs-nav-swipe .rc-tabs-nav .rc-tabs-tab {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
}

.rc-tabs-right .rc-tabs-tab-prev,
.rc-tabs-right .rc-tabs-tab-next {
  margin-top: -2px;
  height: 0;
  width: 0;
  display: block;
  text-align: center;
  line-height: 32px;
  opacity: 0;
  transition:
    width 0.3s,
    height 0.3s,
    opacity 0.3s;
}

.rc-tabs-tab-arrow-show {
  display: flex;
}

.rc-tabs-top .rc-tabs-tab-arrow-show {
  opacity: 1;
  width: 100%;
  height: 32px;
}

.rc-tabs-right .rc-tabs-tab-next {
  bottom: 0;
}

.rc-tabs-right .rc-tabs-tab-next-icon {
  transform: rotate(90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}

.rc-tabs-right .rc-tabs-tab-next-icon:before {
  content: '>';
}

.rc-tabs-right .rc-tabs-tab-prev {
  top: 2px;
}

.rc-tabs-right .rc-tabs-tab-prev-icon {
  transform: rotate(270deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.rc-tabs-right .rc-tabs-tab-prev-icon:before {
}

.rc-tabs-right .rc-tabs-content-animated {
  flex-direction: column;
}

.rc-tabs-right .rc-tabs-content-animated .rc-tabs-tabpane {
  height: 100%;
}

.rc-tabs-right .rc-tabs-ink-bar {
  width: 2px;
  left: 0;
  top: 0;
}

.rc-tabs-right .rc-tabs-tab {
  padding: 16px 24px;
}

.rc-tabs-bottom {
  border-top: 2px solid #f3f3f3;
}

.rc-tabs-bottom .rc-tabs-content {
  width: 100%;
}

.rc-tabs-bottom .rc-tabs-bar {
  border-top: 1px solid #f3f3f3;
}

.rc-tabs-bottom .rc-tabs-nav-container-scrolling {
  padding-left: 32px;
  padding-right: 32px;
}

.rc-tabs-bottom .rc-tabs-nav-scroll {
  width: 99999px;
}

.rc-tabs-bottom .rc-tabs-nav-swipe {
  position: relative;
  left: 0;
}

.rc-tabs-bottom .rc-tabs-nav-swipe .rc-tabs-nav {
  display: flex;
  flex: 1;
  width: 100%;
}

.rc-tabs-bottom .rc-tabs-nav-swipe .rc-tabs-nav .rc-tabs-tab {
  display: flex;
  flex-shrink: 0;
  margin-right: 0;
  padding: 8px 0;
  justify-content: center;
}

.rc-tabs-bottom .rc-tabs-nav-wrap {
  width: 100%;
}

.rc-tabs-bottom .rc-tabs-content-animated {
  flex-direction: row;
}

.rc-tabs-tabpane {
  padding: 1rem 0;
}

.rc-tabs-bottom .rc-tabs-content-animated .rc-tabs-tabpane {
  width: 100%;
}

.rc-tabs-bottom .rc-tabs-tab-next {
  right: 2px;
}

.rc-tabs-bottom .rc-tabs-tab-next-icon:before {
}

.rc-tabs-bottom.rc-tabs-rtl .rc-tabs-tab-next {
  left: 2px;
  right: auto;
}

.rc-tabs-bottom .rc-tabs-tab-prev {
  left: 0;
}

.rc-tabs-bottom .rc-tabs-tab-prev-icon:before {
  background: linear-gradient(270deg, rgba(249, 251, 255, 0), #fff);
}

.rc-tabs-bottom.rc-tabs-rtl .rc-tabs-tab-prev {
  right: 0;
  left: auto;
}

.rc-tabs-bottom .rc-tabs-tab-prev,
.rc-tabs-bottom .rc-tabs-tab-next {
  margin-right: -2px;
  width: 32px;
  height: 100%;
  top: 0;
  text-align: center;
}

.rc-tabs-bottom .rc-tabs-ink-bar {
  height: 1px;
  top: 3px;
  left: 0;
}

.rc-tabs-bottom.rc-tabs-rtl .rc-tabs-ink-bar {
  right: 0;
  left: auto;
}

.rc-tabs-bottom .rc-tabs-tab {
  float: left;
  height: 100%;
  /*margin-right: 30px;*/
}

.rc-tabs-bottom.rc-tabs-rtl .rc-tabs-tab {
  float: right;
  margin-left: 30px;
  margin-right: 0;
}

.rc-tabs-bottom .rc-tabs-tabpane-inactive {
  height: 0;
  overflow: visible;
}

.rc-tabs-top {
}

.rc-tabs-top .rc-tabs-content {
  width: 100%;
}

.rc-tabs-top .rc-tabs-bar {
}

.rc-tabs-top .rc-tabs-nav-container-scrolling {
  padding-left: 50px;
  padding-right: 50px;
}

.rc-tabs-top .rc-tabs-nav-scroll {
  width: auto;
}

.rc-tabs-top .rc-tabs-nav-swipe {
  position: relative;
  left: 0;
}

.rc-tabs-top .rc-tabs-nav-swipe .rc-tabs-nav {
  display: flex;
  flex: 1;
  width: 100%;
}

.rc-tabs-top .rc-tabs-nav-swipe .rc-tabs-nav .rc-tabs-tab {
  display: flex;
  flex-shrink: 0;
  margin-right: 0;
  padding: 8px 0;
  justify-content: center;
}

.rc-tabs-top .rc-tabs-nav-wrap {
  width: 100%;
}

.rc-tabs-top .rc-tabs-content-animated {
  flex-direction: row;
}

.rc-tabs-top .rc-tabs-content-animated .rc-tabs-tabpane {
  width: 100%;
}

.rc-tabs-top .rc-tabs-tab-next {
  right: 2px;
}

.rc-tabs-top .rc-tabs-tab-next-icon:before {
  content: '>';
}

.rc-tabs-top.rc-tabs-rtl .rc-tabs-tab-next {
  left: 2px;
  right: auto;
}

.rc-tabs-top .rc-tabs-tab-prev {
  left: 0;
}

.rc-tabs-top .rc-tabs-tab-prev-icon:before {
  background: linear-gradient(270deg, rgba(249, 251, 255, 0), #fff);
  z-index: 10;
}

.rc-tabs-top.rc-tabs-rtl .rc-tabs-tab-prev {
  right: 0;
  left: auto;
}

.rc-tabs-top .rc-tabs-tab-prev,
.rc-tabs-top .rc-tabs-tab-next {
  margin-right: -2px;
  width: 0;
  height: 0;
  top: 0;
  text-align: center;
  opacity: 0;
  transition:
    width 0.3s,
    height 0.3s,
    opacity 0.3s;
}

.rc-tabs-top .rc-tabs-tab-arrow-show {
  opacity: 1;
  width: 32px;
  height: 100%;
}

.rc-tabs-top .rc-tabs-ink-bar {
  height: 1px;
  bottom: 0;
  left: 0;
}

.rc-tabs-top.rc-tabs-rtl .rc-tabs-ink-bar {
  right: 0;
  left: auto;
}

.rc-tabs-top .rc-tabs-tab {
  float: left;
  height: 100%;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
}

.rc-tabs-top.rc-tabs-rtl .rc-tabs-tab {
  float: right;
  margin-left: 30px;
  margin-right: 0;
}

.rc-tabs-top .rc-tabs-tabpane-inactive {
  height: 0;
  overflow: visible;
}

.simulate-tab-content {
  height: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
  text-align: right;
  width: 67px !important;
}
