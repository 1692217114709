@import '@xq/ui-kit/lib/styles/colors.scss';
@import '@xq/ui-kit/lib/styles/zIndex.scss';

.layout {
  position: relative;
  padding-top: 55px;
  min-height: 100dvh;
  box-sizing: border-box;
}

.header {
  position: fixed;
  width: 100%;
  z-index: $dropdown-zIndex;
  top: 0;
  left: 0;
  background: $white-0;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
