@import 'src/styles/colors';
@import 'src/styles/variables';

@font-face {
  font-display: swap;
  font-family: 'JetBrains Mono';
  src:
    local('JetBrains Mono Medium'),
    local('IBMPlexMono-Medium'),
    url('@componentsStyles/fonts/JetBrainsMono/JetBrainsMono-Medium.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/JetBrainsMono/JetBrainsMono-Medium.ttf')
      format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'JetBrains Mono';
  src:
    local('JetBrains Mono SemiBold'),
    local('IBMPlexMono-SemiBold'),
    url('@componentsStyles/fonts/JetBrainsMono/JetBrainsMono-SemiBold.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/JetBrainsMono/JetBrainsMono-SemiBold.ttf')
      format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Proxima Nova';
  src: url('@componentsStyles/fonts/ProximaNova/ProximaNova-Bold.eot');
  src:
    local('Proxima Nova Bold'),
    local('ProximaNova-Bold'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Bold.eot')
      format('embedded-opentype'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Bold.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Bold.woff')
      format('woff'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Bold.ttf')
      format('truetype');
  font-weight: bold;
  font-style: normal;
}

$jetBrainsFont: 'JetBrains Mono', monospace;
$proximaNovaFont: 'JetBrains Mono', sans-serif;

:export {
  jetBrainsFont: $jetBrainsFont;
  proximaNovaFont: $proximaNovaFont;
}

.placeholderWrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;

  img {
    width: 100%;
  }
}

.chartLoaderWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &Left {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    &Right {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: flex-end;
    }
  }

  &Main {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &Loader {
    padding: 40px 0;
    margin: 0 auto;
  }
}

.customChartHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  flex-wrap: wrap;

  &Wrap {
    margin-top: 20px;
  }

  &Title {
    display: flex;
    align-items: center;
    font-size: 20px;

    &MapIcon {
      cursor: pointer;
      margin-left: 10px;
    }
  }
}

.chartWrapper {
  width: 100%;
  height: 250px;
}

.chartLegend {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 76px;
  margin-left: auto;

  &__items {
    display: flex;
    gap: 40px;
  }

  &__item {
    display: flex;
    flex-direction: column;

    &:hover {
      cursor: pointer;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 4px;

    > p {
      margin: 0;
      font-size: 12px;
      color: $gray-80;
      text-decoration: underline;
      text-decoration-style: dashed;
      text-underline-offset: 3px;
    }
  }

  &__value {
    max-height: 40px;
    min-height: 26px;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 0 0 auto;
    align-self: flex-end;
    padding: 3px 10px 5px 10px;
    font-weight: bold;
    color: $gray-100;
    font-size: 32px;
    line-height: 32px;
    text-align: right;
    border-radius: 20px;
    box-sizing: border-box;

    &:hover {
      transition: color 200ms ease;
      color: $gold;
    }

    &_active {
      color: $white-0;
      background-color: var(--lineColor);

      &:hover {
        color: $white-0;
      }
    }

    &_default {
      cursor: default;
    }

    &_disabled {
      &:hover {
        cursor: default;
        color: $black;
      }
    }
  }

  &__settedValue {
    height: 25.5px;
  }

  &__unit {
    display: none;

    &_active {
      display: block;
      text-transform: uppercase;
      font-size: 8px;
      line-height: 10px;
      color: $white-0;
    }
  }

  &__date {
    margin: 0;
    text-align: right;
    font-size: 14px;
    color: $gray-80;
  }
}

.legendSquare {
  height: 6px;
  width: 18px;
  border-radius: 1px;
}

.tick {
  font-size: 10px;
  font-family: $jetBrainsFont;
  fill: $gray-50;
}

.dividerIcon {
  margin: 0 10px;
}

.brushStyles {
  margin-top: 20px;
  font-family: $jetBrainsFont;
  font-size: 10px;

  &:focus {
    background-color: yellow;
  }
}
