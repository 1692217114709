$modal-zIndex: 1000;
$dropdown-icon-zIndex: 101;
$dropdown-zIndex: 100;
$zIndex-5: 5;
$zIndex-1: 1;

/* stylelint-disable */
:export {
  modalZIndex: $modal-zIndex;
  dropdownZIndex: $dropdown-zIndex;
}
/* stylelint-enable */
