@import 'src/styles/colors';
@import 'src/styles/elevation';
@import 'src/styles/variables';
@import 'src/styles/zIndex';

.wrapper {
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  padding: 0 10px;
}

.dropdown {
  &-header {
    display: flex;
    align-items: center;

    path {
      fill: $gray-20;
      transition: $transition-duration $transition-timing-function;
    }

    &:hover {
      cursor: pointer;
      path {
        fill: $gray-60;
      }
    }

    &--disabled {
      @extend .dropdown-header;
      &:hover {
        cursor: default;
      }
    }
  }

  &-menu {
    @include elevation(SelectList);
    position: absolute;
    right: 0;
    bottom: -37px;
    z-index: $dropdown-zIndex;
    padding: 10px 20px;
    transition: $transition-duration $transition-timing-function;
    cursor: pointer;
    background: $white-0;
    border-radius: 0px 0px 4px 4px;

    &:hover {
      color: $gold;
    }
  }
}

.divider {
  border-color: $gray-20;
}
