@import 'src/styles/colors';
@import 'src/styles/variables';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  transition: $transition-duration $transition-timing-function;
}

.flex {
  display: flex;
  align-items: center;
}

.card {
  position: relative;
  padding: 20px 40px;

  &--loading {
    position: relative;
    padding: 20px 40px;
    path {
      fill: $gray-20;
    }
  }
}
