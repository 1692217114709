@import 'src/styles/colors.scss';
@import 'src/styles/zIndex.scss';
@import 'src/styles/variables.scss';
@import 'src/styles/mixins';
@import '@components/Typography/Typography.module.scss';

$margin-left: 10px;
$margin-s: 4px;

.form {
  position: relative;
  padding-top: 23px;
  padding-bottom: 4px;

  &--disabled {
    @extend .form;
    background-color: $white-5;
  }
}

.input {
  @extend .typography_label-1;
  -webkit-appearance: none;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  outline: none;
  border: 0;
  border-bottom: 1px solid $gray-60;
  padding: 0 0 $margin-left $margin-left;
  color: $gray-100;
  background: none;
  z-index: $zIndex-1;
  transition: $transition-duration $transition-timing-function;
  transition-property: border;
  top: 4px;
  max-height: 610px;
  overflow-y: auto;
  @include scrollbar-transparent();

  &:disabled {
    background: $white-5;
    cursor: not-allowed;
    border-radius: 4px 4px 0 0;
    z-index: 0;
  }

  &:hover {
    color: $gray-80;
    border-color: $gray-80;
    cursor: pointer;
  }

  &:disabled:hover {
    cursor: not-allowed;
    border-bottom: 1px solid $gray-60;
  }

  &:focus {
    border-bottom: 1px solid $gold;
    z-index: $zIndex-5;
  }

  &_error {
    border-bottom: 1px solid $red-70;

    &:hover,
    &:focus {
      border-bottom: 1px solid $red-70;
    }
  }
}

textarea {
  resize: none;
}

.label {
  @extend .typography_label-1;
  position: absolute;
  top: 19px;
  transition: $transition-duration $transition-timing-function;
  transition-property: color, top, opacity, font-size, line-height;
  left: 0;
  color: $gray-60;
  padding: $margin-s $margin-s $margin-s $margin-left;
}

.input:not(:-moz-placeholder-shown) + .label {
  font-size: 12px;
  line-height: 16px;
  top: 0;
  opacity: 1;
}

.input:focus + .label,
.input:not(:placeholder-shown) + .label {
  font-size: 12px;
  line-height: 16px;
  top: 0;
  opacity: 1;
}

.input:hover:disabled + .label {
  color: $gray-60;
}

.input:disabled + .label {
  background: $white-5;
}

.input:hover + .label {
  color: $gray-80;
}

.input:focus + .label {
  color: $gold;
}

.input_error:hover + .label,
.input_error:focus + .label {
  color: $red-70;
}

.required-symbol {
  color: $red-70;
  margin-left: $margin-s;
}

.error-message {
  position: absolute;
  margin-top: $margin-s;
  margin-left: $margin-left;
  display: block;
}

.markdown-area {
  min-height: 91px;
}
