@import 'src/styles/colors';

.skeleton_placeholders {
  background: $white-10;
  background: linear-gradient(
    110deg,
    $white-10 8%,
    $white-5 18%,
    $white-10 33%
  );
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;

  &--rectangle {
    @extend .skeleton_placeholders;
  }

  &--circle {
    @extend .skeleton_placeholders;
    border-radius: 100%;
  }

  &--square {
    @extend .skeleton_placeholders;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
