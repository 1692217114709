@import '@componentsStyles/colors.scss';
@import '@componentsStyles/zIndex.scss';
@import '@componentsStyles/variables.scss';
@import '@componentsStyles/elevation.scss';
@import '@componentsStyles/mixins.scss';
@import '@components/Typography/Typography.module.scss';

.form {
  position: relative;
}

.wrapper {
  position: relative;

  &--disabled {
    position: relative;
  }
}

.list {
  @include elevation(SelectList);
  position: absolute;
  top: 56px;
  background: $white-0;
  border-radius: 0 0 $border-radius $border-radius;
  width: 100%;
  z-index: $dropdown-zIndex;
  max-height: 200px;
  @include scrollbar();
  overflow-y: auto;

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
  }

  &-item {
    @extend .typography_label-1;
    padding: 10px 20px;
    cursor: pointer;
    transition: $transition-duration $transition-timing-function;

    &--selected {
      @extend .list-item;
      color: $gold;
      background: $background-hover;
    }

    &:hover {
      color: $gold;
    }
  }
}

.loader {
  padding: 28px 14px 32px 20px;
  display: flex;
  justify-content: flex-end;
}

.close {
  transition: $transition-duration $transition-timing-function;
  margin-left: 8px;
  z-index: $zIndex-5;
  cursor: pointer;
  display: none;
  position: absolute;
  right: 40px;
  bottom: 39px;

  &:hover {
    path {
      fill: $gray-80;
    }
  }

  &--hidden {
    display: none;
  }
}

.icon {
  position: absolute;
  right: 10px;
  bottom: 39px;
  fill: $gray-60;
  z-index: $zIndex-5;
  transition: $transition-duration $transition-timing-function;
}

.wrapper:hover .close {
  display: inline-block;
}

.input-select {
  padding-right: 70px;
}
