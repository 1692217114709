@import 'src/styles/colors';

.header {
  display: flex;
  align-items: center;
  margin-left: -26px;
  margin-bottom: 12px;
}

.divider {
  margin: 0 -10px;

  &--disabled {
    @extend .divider;
    border-color: $gray-40;
  }
}

.logo {
  height: 20px;
}
