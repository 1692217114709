.btn-icon {
  display: flex;
}

.icon-back {
  margin-right: 10px;
}

.icon-next {
  margin-left: 10px;
}
