@import '@componentsStyles/colors.scss';
@import '@componentsStyles/variables.scss';
@import '@components/Typography/Typography.module.scss';

.picker {
  color: $gray-60;
  border-radius: 0;
  background: inherit;
  transition: $transition-duration $transition-timing-function;
  border: none;
  border-bottom: 1px solid $gray-60;

  &:focus {
    border: none;
    border-bottom: 1px solid $gray-60;
  }

  &:hover {
    .ant-picker-suffix {
      color: $gray-80;
      fill: $gray-80;
    }
  }

  input::-moz-placeholder {
    color: $gray-60;
  }

  input::placeholder {
    color: $gray-60;
  }

  &.ant-picker-disabled {
    color: $gray-60;
  }

  &--disabled {
    &:hover {
      .ant-picker-suffix {
        color: $gray-60;
        fill: $gray-60;
      }
    }
  }
}

.range-picker {
  @extend .typography_body-4;
  height: 34px;
  padding: 10px;

  input {
    @extend .typography_body-4;
    width: 80px !important;
  }

  &--month {
    input {
      width: 60px !important;
    }
  }
}

.date-picker {
  @extend .typography_label-1;
  width: 100%;
  height: 56px;
  padding: 21px 10px 12px;
  background: inherit;

  input {
    @extend .typography_label-1;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 20px !important;
  }

  &--month {
    height: 34px;
    padding: 8px 10px;

    input {
      width: 60px !important;
    }
  }
}

.ant-picker-suffix {
  background: inherit;
  transition: $transition-duration $transition-timing-function;
  color: $gray-60;
  fill: $gray-60;
}
