@import '@componentsStyles/colors.scss';
@import '@componentsStyles/elevation.scss';
@import '@componentsStyles/variables.scss';
@import '@componentsStyles/breakpoints.scss';
@import '@componentsStyles/mixins.scss';

.header {
  width: -moz-fit-content;
  width: fit-content;
  line-height: 0;
  cursor: pointer;

  &-title {
    transition: $transition-duration $transition-timing-function;
    margin-left: 30px;
    margin-bottom: 2px;

    &:hover {
      color: $active-side-menu-item;
    }
  }
}

.card {
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: $transition-duration $transition-timing-function;

  &--loading {
    padding: 20px 40px;
  }
}

.variant-wrapper {
  transition: $transition-duration $transition-timing-function;
  padding: 20px 30px;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    background-color: $white-0;
  }

  &--unavailable {
    &:hover {
      background-color: transparent;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    &-logo-container {
      display: flex;
      align-items: flex-start;
      gap: 10px;
    }

    &-image {
      height: 20px;
      max-width: 66px;
      -o-object-fit: fill;
         object-fit: fill;

      &--unavailable {
        filter: grayscale(1);
      }
    }
  }

  &-unlock {
    &:hover {
      span {
        color: $gray-100;
      }

      svg {
        fill: $gray-100;
      }
    }

    span {
      color: $gray-40;
      transition: $transition-duration $transition-timing-function;
    }

    svg {
      fill: $gray-40;
      transition: $transition-duration $transition-timing-function;
    }
  }

  &-full-access {
    &:hover {
      span {
        color: $gray-80;
      }

      svg {
        fill: $gray-80;
      }
    }

    span {
      color: $black;
      transition: $transition-duration $transition-timing-function;
    }

    svg {
      fill: $black;
      transition: $transition-duration $transition-timing-function;
    }
  }
}

.options-wrapper {
  display: grid;
  grid-template-columns: repeat(4, minmax(44px, 1fr));
  -moz-column-gap: 10px;
       column-gap: 10px;
  row-gap: 20px;

  &-item {
    transition: $transition-duration $transition-timing-function;
    min-width: 44px;
    max-width: 86px;
    width: -moz-fit-content;
    width: fit-content;

    &:hover {
      cursor: pointer;
      color: $gold;
    }

    &--unavailable {
      color: $gray-40;

      &:hover {
        color: $gray-40;
      }
    }
  }
}
