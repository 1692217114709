@import 'src/styles/colors.scss';

.spinner {
  animation: rotate 0.8s infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
