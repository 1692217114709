.Toastify__toast {
  box-shadow: 0 1px 2px rgba(18, 18, 18, 0.16);
  padding: 20px;
  border-radius: 4px 0 0 4px;
  border: 0;
  width: 455px;
  margin-bottom: 20px;
}

.Toastify__toast span {
  overflow: hidden;
  display: -webkit-box;
  display: -moz-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
}

.Toastify__toast-body {
  padding: 0;
}

.Toastify__toast-icon {
  margin-inline-end: 16px;
}

.Toastify__toast-container {
  padding: 0;
}

.Toastify__toast-container--top-right {
  top: 60px;
  right: 0;
}

:root {
  --toastify-text-color-light: #121212;
  --toastify-toast-width: 455px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 60px;
  --toastify-toast-max-height: 140px;
}
