.fi {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  display: inline-block;
}

.fi-ad {
  background-image: url('@componentsIcons/flags/ad.svg');
}

.fi-ae {
  background-image: url('@componentsIcons/flags/ae.svg');
}

.fi-af {
  background-image: url('@componentsIcons/flags/af.svg');
}

.fi-ag {
  background-image: url('@componentsIcons/flags/ag.svg');
}

.fi-ai {
  background-image: url('@componentsIcons/flags/ai.svg');
}

.fi-al {
  background-image: url('@componentsIcons/flags/al.svg');
}

.fi-am {
  background-image: url('@componentsIcons/flags/am.svg');
}

.fi-ao {
  background-image: url('@componentsIcons/flags/ao.svg');
}

.fi-aq {
  background-image: url('@componentsIcons/flags/aq.svg');
}

.fi-ar {
  background-image: url('@componentsIcons/flags/ar.svg');
}

.fi-as {
  background-image: url('@componentsIcons/flags/as.svg');
}

.fi-at {
  background-image: url('@componentsIcons/flags/at.svg');
}

.fi-au {
  background-image: url('@componentsIcons/flags/au.svg');
}

.fi-aw {
  background-image: url('@componentsIcons/flags/aw.svg');
}

.fi-ax {
  background-image: url('@componentsIcons/flags/ax.svg');
}

.fi-az {
  background-image: url('@componentsIcons/flags/az.svg');
}

.fi-ba {
  background-image: url('@componentsIcons/flags/ba.svg');
}

.fi-bb {
  background-image: url('@componentsIcons/flags/bb.svg');
}

.fi-bd {
  background-image: url('@componentsIcons/flags/bd.svg');
}

.fi-be {
  background-image: url('@componentsIcons/flags/be.svg');
}

.fi-bf {
  background-image: url('@componentsIcons/flags/bf.svg');
}

.fi-bg {
  background-image: url('@componentsIcons/flags/bg.svg');
}

.fi-bh {
  background-image: url('@componentsIcons/flags/bh.svg');
}

.fi-bi {
  background-image: url('@componentsIcons/flags/bi.svg');
}

.fi-bj {
  background-image: url('@componentsIcons/flags/bj.svg');
}

.fi-bl {
  background-image: url('@componentsIcons/flags/bl.svg');
}

.fi-bm {
  background-image: url('@componentsIcons/flags/bm.svg');
}

.fi-bn {
  background-image: url('@componentsIcons/flags/bn.svg');
}

.fi-bo {
  background-image: url('@componentsIcons/flags/bo.svg');
}

.fi-bq {
  background-image: url('@componentsIcons/flags/bq.svg');
}

.fi-br {
  background-image: url('@componentsIcons/flags/br.svg');
}

.fi-bs {
  background-image: url('@componentsIcons/flags/bs.svg');
}

.fi-bt {
  background-image: url('@componentsIcons/flags/bt.svg');
}

.fi-bv {
  background-image: url('@componentsIcons/flags/bv.svg');
}

.fi-bw {
  background-image: url('@componentsIcons/flags/bw.svg');
}

.fi-by {
  background-image: url('@componentsIcons/flags/by.svg');
}

.fi-bz {
  background-image: url('@componentsIcons/flags/bz.svg');
}

.fi-ca {
  background-image: url('@componentsIcons/flags/ca.svg');
}

.fi-cc {
  background-image: url('@componentsIcons/flags/cc.svg');
}

.fi-cd {
  background-image: url('@componentsIcons/flags/cd.svg');
}

.fi-cf {
  background-image: url('@componentsIcons/flags/cf.svg');
}

.fi-cg {
  background-image: url('@componentsIcons/flags/cg.svg');
}

.fi-ch {
  background-image: url('@componentsIcons/flags/ch.svg');
}

.fi-ci {
  background-image: url('@componentsIcons/flags/ci.svg');
}

.fi-ck {
  background-image: url('@componentsIcons/flags/ck.svg');
}

.fi-cl {
  background-image: url('@componentsIcons/flags/cl.svg');
}

.fi-cm {
  background-image: url('@componentsIcons/flags/cm.svg');
}

.fi-cn {
  background-image: url('@componentsIcons/flags/cn.svg');
}

.fi-co {
  background-image: url('@componentsIcons/flags/co.svg');
}

.fi-cr {
  background-image: url('@componentsIcons/flags/cr.svg');
}

.fi-cu {
  background-image: url('@componentsIcons/flags/cu.svg');
}

.fi-cv {
  background-image: url('@componentsIcons/flags/cv.svg');
}

.fi-cw {
  background-image: url('@componentsIcons/flags/cw.svg');
}

.fi-cx {
  background-image: url('@componentsIcons/flags/cx.svg');
}

.fi-cy {
  background-image: url('@componentsIcons/flags/cy.svg');
}

.fi-cz {
  background-image: url('@componentsIcons/flags/cz.svg');
}

.fi-de {
  background-image: url('@componentsIcons/flags/de.svg');
}

.fi-dj {
  background-image: url('@componentsIcons/flags/dj.svg');
}

.fi-dk {
  background-image: url('@componentsIcons/flags/dk.svg');
}

.fi-dm {
  background-image: url('@componentsIcons/flags/dm.svg');
}

.fi-do {
  background-image: url('@componentsIcons/flags/do.svg');
}

.fi-dz {
  background-image: url('@componentsIcons/flags/dz.svg');
}

.fi-ec {
  background-image: url('@componentsIcons/flags/ec.svg');
}

.fi-ee {
  background-image: url('@componentsIcons/flags/ee.svg');
}

.fi-eg {
  background-image: url('@componentsIcons/flags/eg.svg');
}

.fi-eh {
  background-image: url('@componentsIcons/flags/eh.svg');
}

.fi-er {
  background-image: url('@componentsIcons/flags/er.svg');
}

.fi-es {
  background-image: url('@componentsIcons/flags/es.svg');
}

.fi-et {
  background-image: url('@componentsIcons/flags/et.svg');
}

.fi-fi {
  background-image: url('@componentsIcons/flags/fi.svg');
}

.fi-fj {
  background-image: url('@componentsIcons/flags/fj.svg');
}

.fi-fk {
  background-image: url('@componentsIcons/flags/fk.svg');
}

.fi-fm {
  background-image: url('@componentsIcons/flags/fm.svg');
}

.fi-fo {
  background-image: url('@componentsIcons/flags/fo.svg');
}

.fi-fr {
  background-image: url('@componentsIcons/flags/fr.svg');
}

.fi-ga {
  background-image: url('@componentsIcons/flags/ga.svg');
}

.fi-gb {
  background-image: url('@componentsIcons/flags/gb.svg');
}

.fi-gd {
  background-image: url('@componentsIcons/flags/gd.svg');
}

.fi-ge {
  background-image: url('@componentsIcons/flags/ge.svg');
}

.fi-gf {
  background-image: url('@componentsIcons/flags/gf.svg');
}

.fi-gg {
  background-image: url('@componentsIcons/flags/gg.svg');
}

.fi-gh {
  background-image: url('@componentsIcons/flags/gh.svg');
}

.fi-gi {
  background-image: url('@componentsIcons/flags/gi.svg');
}

.fi-gl {
  background-image: url('@componentsIcons/flags/gl.svg');
}

.fi-gm {
  background-image: url('@componentsIcons/flags/gm.svg');
}

.fi-gn {
  background-image: url('@componentsIcons/flags/gn.svg');
}

.fi-gp {
  background-image: url('@componentsIcons/flags/gp.svg');
}

.fi-gq {
  background-image: url('@componentsIcons/flags/gq.svg');
}

.fi-gr {
  background-image: url('@componentsIcons/flags/gr.svg');
}

.fi-gs {
  background-image: url('@componentsIcons/flags/gs.svg');
}

.fi-gt {
  background-image: url('@componentsIcons/flags/gt.svg');
}

.fi-gu {
  background-image: url('@componentsIcons/flags/gu.svg');
}

.fi-gw {
  background-image: url('@componentsIcons/flags/gw.svg');
}

.fi-gy {
  background-image: url('@componentsIcons/flags/gy.svg');
}

.fi-hk {
  background-image: url('@componentsIcons/flags/hk.svg');
}

.fi-hm {
  background-image: url('@componentsIcons/flags/hm.svg');
}

.fi-hn {
  background-image: url('@componentsIcons/flags/hn.svg');
}

.fi-hr {
  background-image: url('@componentsIcons/flags/hr.svg');
}

.fi-ht {
  background-image: url('@componentsIcons/flags/ht.svg');
}

.fi-hu {
  background-image: url('@componentsIcons/flags/hu.svg');
}

.fi-id {
  background-image: url('@componentsIcons/flags/id.svg');
}

.fi-ie {
  background-image: url('@componentsIcons/flags/ie.svg');
}

.fi-il {
  background-image: url('@componentsIcons/flags/il.svg');
}

.fi-im {
  background-image: url('@componentsIcons/flags/im.svg');
}

.fi-in {
  background-image: url('@componentsIcons/flags/in.svg');
}

.fi-io {
  background-image: url('@componentsIcons/flags/io.svg');
}

.fi-iq {
  background-image: url('@componentsIcons/flags/iq.svg');
}

.fi-ir {
  background-image: url('@componentsIcons/flags/ir.svg');
}

.fi-is {
  background-image: url('@componentsIcons/flags/is.svg');
}

.fi-it {
  background-image: url('@componentsIcons/flags/it.svg');
}

.fi-je {
  background-image: url('@componentsIcons/flags/je.svg');
}

.fi-jm {
  background-image: url('@componentsIcons/flags/jm.svg');
}

.fi-jo {
  background-image: url('@componentsIcons/flags/jo.svg');
}

.fi-jp {
  background-image: url('@componentsIcons/flags/jp.svg');
}

.fi-ke {
  background-image: url('@componentsIcons/flags/ke.svg');
}

.fi-kg {
  background-image: url('@componentsIcons/flags/kg.svg');
}

.fi-kh {
  background-image: url('@componentsIcons/flags/kh.svg');
}

.fi-ki {
  background-image: url('@componentsIcons/flags/ki.svg');
}

.fi-km {
  background-image: url('@componentsIcons/flags/km.svg');
}

.fi-kn {
  background-image: url('@componentsIcons/flags/kn.svg');
}

.fi-kp {
  background-image: url('@componentsIcons/flags/kp.svg');
}

.fi-kr {
  background-image: url('@componentsIcons/flags/kr.svg');
}

.fi-kw {
  background-image: url('@componentsIcons/flags/kw.svg');
}

.fi-ky {
  background-image: url('@componentsIcons/flags/ky.svg');
}

.fi-kz {
  background-image: url('@componentsIcons/flags/kz.svg');
}

.fi-la {
  background-image: url('@componentsIcons/flags/la.svg');
}

.fi-lb {
  background-image: url('@componentsIcons/flags/lb.svg');
}

.fi-lc {
  background-image: url('@componentsIcons/flags/lc.svg');
}

.fi-li {
  background-image: url('@componentsIcons/flags/li.svg');
}

.fi-lk {
  background-image: url('@componentsIcons/flags/lk.svg');
}

.fi-lr {
  background-image: url('@componentsIcons/flags/lr.svg');
}

.fi-ls {
  background-image: url('@componentsIcons/flags/ls.svg');
}

.fi-lt {
  background-image: url('@componentsIcons/flags/lt.svg');
}

.fi-lu {
  background-image: url('@componentsIcons/flags/lu.svg');
}

.fi-lv {
  background-image: url('@componentsIcons/flags/lv.svg');
}

.fi-ly {
  background-image: url('@componentsIcons/flags/ly.svg');
}

.fi-ma {
  background-image: url('@componentsIcons/flags/ma.svg');
}

.fi-mc {
  background-image: url('@componentsIcons/flags/mc.svg');
}

.fi-md {
  background-image: url('@componentsIcons/flags/md.svg');
}

.fi-me {
  background-image: url('@componentsIcons/flags/me.svg');
}

.fi-mf {
  background-image: url('@componentsIcons/flags/mf.svg');
}

.fi-mg {
  background-image: url('@componentsIcons/flags/mg.svg');
}

.fi-mh {
  background-image: url('@componentsIcons/flags/mh.svg');
}

.fi-mk {
  background-image: url('@componentsIcons/flags/mk.svg');
}

.fi-ml {
  background-image: url('@componentsIcons/flags/ml.svg');
}

.fi-mm {
  background-image: url('@componentsIcons/flags/mm.svg');
}

.fi-mn {
  background-image: url('@componentsIcons/flags/mn.svg');
}

.fi-mo {
  background-image: url('@componentsIcons/flags/mo.svg');
}

.fi-mp {
  background-image: url('@componentsIcons/flags/mp.svg');
}

.fi-mq {
  background-image: url('@componentsIcons/flags/mq.svg');
}

.fi-mr {
  background-image: url('@componentsIcons/flags/mr.svg');
}

.fi-ms {
  background-image: url('@componentsIcons/flags/ms.svg');
}

.fi-mt {
  background-image: url('@componentsIcons/flags/mt.svg');
}

.fi-mu {
  background-image: url('@componentsIcons/flags/mu.svg');
}

.fi-mv {
  background-image: url('@componentsIcons/flags/mv.svg');
}

.fi-mw {
  background-image: url('@componentsIcons/flags/mw.svg');
}

.fi-mx {
  background-image: url('@componentsIcons/flags/mx.svg');
}

.fi-my {
  background-image: url('@componentsIcons/flags/my.svg');
}

.fi-mz {
  background-image: url('@componentsIcons/flags/mz.svg');
}

.fi-na {
  background-image: url('@componentsIcons/flags/na.svg');
}

.fi-nc {
  background-image: url('@componentsIcons/flags/nc.svg');
}

.fi-ne {
  background-image: url('@componentsIcons/flags/ne.svg');
}

.fi-nf {
  background-image: url('@componentsIcons/flags/nf.svg');
}

.fi-ng {
  background-image: url('@componentsIcons/flags/ng.svg');
}

.fi-ni {
  background-image: url('@componentsIcons/flags/ni.svg');
}

.fi-nl {
  background-image: url('@componentsIcons/flags/nl.svg');
}

.fi-no {
  background-image: url('@componentsIcons/flags/no.svg');
}

.fi-np {
  background-image: url('@componentsIcons/flags/np.svg');
}

.fi-nr {
  background-image: url('@componentsIcons/flags/nr.svg');
}

.fi-nu {
  background-image: url('@componentsIcons/flags/nu.svg');
}

.fi-nz {
  background-image: url('@componentsIcons/flags/nz.svg');
}

.fi-om {
  background-image: url('@componentsIcons/flags/om.svg');
}

.fi-pa {
  background-image: url('@componentsIcons/flags/pa.svg');
}

.fi-pe {
  background-image: url('@componentsIcons/flags/pe.svg');
}

.fi-pf {
  background-image: url('@componentsIcons/flags/pf.svg');
}

.fi-pg {
  background-image: url('@componentsIcons/flags/pg.svg');
}

.fi-ph {
  background-image: url('@componentsIcons/flags/ph.svg');
}

.fi-pk {
  background-image: url('@componentsIcons/flags/pk.svg');
}

.fi-pl {
  background-image: url('@componentsIcons/flags/pl.svg');
}

.fi-pm {
  background-image: url('@componentsIcons/flags/pm.svg');
}

.fi-pn {
  background-image: url('@componentsIcons/flags/pn.svg');
}

.fi-pr {
  background-image: url('@componentsIcons/flags/pr.svg');
}

.fi-ps {
  background-image: url('@componentsIcons/flags/ps.svg');
}

.fi-pt {
  background-image: url('@componentsIcons/flags/pt.svg');
}

.fi-pw {
  background-image: url('@componentsIcons/flags/pw.svg');
}

.fi-py {
  background-image: url('@componentsIcons/flags/py.svg');
}

.fi-qa {
  background-image: url('@componentsIcons/flags/qa.svg');
}

.fi-re {
  background-image: url('@componentsIcons/flags/re.svg');
}

.fi-ro {
  background-image: url('@componentsIcons/flags/ro.svg');
}

.fi-rs {
  background-image: url('@componentsIcons/flags/rs.svg');
}

.fi-ru {
  background-image: url('@componentsIcons/flags/ru.svg');
}

.fi-rw {
  background-image: url('@componentsIcons/flags/rw.svg');
}

.fi-sa {
  background-image: url('@componentsIcons/flags/sa.svg');
}

.fi-sb {
  background-image: url('@componentsIcons/flags/sb.svg');
}

.fi-sc {
  background-image: url('@componentsIcons/flags/sc.svg');
}

.fi-sd {
  background-image: url('@componentsIcons/flags/sd.svg');
}

.fi-se {
  background-image: url('@componentsIcons/flags/se.svg');
}

.fi-sg {
  background-image: url('@componentsIcons/flags/sg.svg');
}

.fi-sh {
  background-image: url('@componentsIcons/flags/sh.svg');
}

.fi-si {
  background-image: url('@componentsIcons/flags/si.svg');
}

.fi-sj {
  background-image: url('@componentsIcons/flags/sj.svg');
}

.fi-sk {
  background-image: url('@componentsIcons/flags/sk.svg');
}

.fi-sl {
  background-image: url('@componentsIcons/flags/sl.svg');
}

.fi-sm {
  background-image: url('@componentsIcons/flags/sm.svg');
}

.fi-sn {
  background-image: url('@componentsIcons/flags/sn.svg');
}

.fi-so {
  background-image: url('@componentsIcons/flags/so.svg');
}

.fi-sr {
  background-image: url('@componentsIcons/flags/sr.svg');
}

.fi-ss {
  background-image: url('@componentsIcons/flags/ss.svg');
}

.fi-st {
  background-image: url('@componentsIcons/flags/st.svg');
}

.fi-sv {
  background-image: url('@componentsIcons/flags/sv.svg');
}

.fi-sx {
  background-image: url('@componentsIcons/flags/sx.svg');
}

.fi-sy {
  background-image: url('@componentsIcons/flags/sy.svg');
}

.fi-sz {
  background-image: url('@componentsIcons/flags/sz.svg');
}

.fi-tc {
  background-image: url('@componentsIcons/flags/tc.svg');
}

.fi-td {
  background-image: url('@componentsIcons/flags/td.svg');
}

.fi-tf {
  background-image: url('@componentsIcons/flags/tf.svg');
}

.fi-tg {
  background-image: url('@componentsIcons/flags/tg.svg');
}

.fi-th {
  background-image: url('@componentsIcons/flags/th.svg');
}

.fi-tj {
  background-image: url('@componentsIcons/flags/tj.svg');
}

.fi-tk {
  background-image: url('@componentsIcons/flags/tk.svg');
}

.fi-tl {
  background-image: url('@componentsIcons/flags/tl.svg');
}

.fi-tm {
  background-image: url('@componentsIcons/flags/tm.svg');
}

.fi-tn {
  background-image: url('@componentsIcons/flags/tn.svg');
}

.fi-to {
  background-image: url('@componentsIcons/flags/to.svg');
}

.fi-tr {
  background-image: url('@componentsIcons/flags/tr.svg');
}

.fi-tt {
  background-image: url('@componentsIcons/flags/tt.svg');
}

.fi-tv {
  background-image: url('@componentsIcons/flags/tv.svg');
}

.fi-tw {
  background-image: url('@componentsIcons/flags/tw.svg');
}

.fi-tz {
  background-image: url('@componentsIcons/flags/tz.svg');
}

.fi-ua {
  background-image: url('@componentsIcons/flags/ua.svg');
}

.fi-ug {
  background-image: url('@componentsIcons/flags/ug.svg');
}

.fi-um {
  background-image: url('@componentsIcons/flags/um.svg');
}

.fi-us {
  background-image: url('@componentsIcons/flags/us.svg');
}

.fi-uy {
  background-image: url('@componentsIcons/flags/uy.svg');
}

.fi-uz {
  background-image: url('@componentsIcons/flags/uz.svg');
}

.fi-va {
  background-image: url('@componentsIcons/flags/va.svg');
}

.fi-vc {
  background-image: url('@componentsIcons/flags/vc.svg');
}

.fi-ve {
  background-image: url('@componentsIcons/flags/ve.svg');
}

.fi-vg {
  background-image: url('@componentsIcons/flags/vg.svg');
}

.fi-vi {
  background-image: url('@componentsIcons/flags/vi.svg');
}

.fi-vn {
  background-image: url('@componentsIcons/flags/vn.svg');
}

.fi-vu {
  background-image: url('@componentsIcons/flags/vu.svg');
}

.fi-wf {
  background-image: url('@componentsIcons/flags/wf.svg');
}

.fi-ws {
  background-image: url('@componentsIcons/flags/ws.svg');
}

.fi-ye {
  background-image: url('@componentsIcons/flags/ye.svg');
}

.fi-yt {
  background-image: url('@componentsIcons/flags/yt.svg');
}

.fi-za {
  background-image: url('@componentsIcons/flags/za.svg');
}

.fi-zm {
  background-image: url('@componentsIcons/flags/zm.svg');
}

.fi-zw {
  background-image: url('@componentsIcons/flags/zw.svg');
}

.fi-ac {
  background-image: url('@componentsIcons/flags/ac.svg');
}

.fi-cp {
  background-image: url('@componentsIcons/flags/cp.svg');
}

.fi-dg {
  background-image: url('@componentsIcons/flags/dg.svg');
}

.fi-ea {
  background-image: url('@componentsIcons/flags/ea.svg');
}

.fi-es-ct {
  background-image: url('@componentsIcons/flags/es-ct.svg');
}

.fi-es-ga {
  background-image: url('@componentsIcons/flags/es-ga.svg');
}

.fi-eu {
  background-image: url('@componentsIcons/flags/eu.svg');
}

.fi-gb-eng {
  background-image: url('@componentsIcons/flags/gb-eng.svg');
}

.fi-gb-nir {
  background-image: url('@componentsIcons/flags/gb-nir.svg');
}

.fi-gb-sct {
  background-image: url('@componentsIcons/flags/gb-sct.svg');
}

.fi-gb-wls {
  background-image: url('@componentsIcons/flags/gb-wls.svg');
}

.fi-ic {
  background-image: url('@componentsIcons/flags/ic.svg');
}

.fi-ta {
  background-image: url('@componentsIcons/flags/ta.svg');
}

.fi-un {
  background-image: url('@componentsIcons/flags/un.svg');
}

.fi-xk {
  background-image: url('@componentsIcons/flags/xk.svg');
}
