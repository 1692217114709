.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 2px;
  padding: 8px 4px;
  transition: background-color 200ms linear;

  > div {
    height: 24px;
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 1);
  }
}

.active {
  background-color: rgba(255, 255, 255, 1);
}

.modal {
  position: absolute;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 2px rgba(18, 18, 18, 0.2);
  border-radius: 4px;
  width: 232px;
  padding: 20px 10px;
  right: 30px;
  top: 100px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #8f8f8f;
  margin: 0 0 20px;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 10px;
}
