@import 'src/styles/variables';

.header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  transition: $transition-duration $transition-timing-function;
}

.row {
  margin-top: 8px;
}
