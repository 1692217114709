@import 'src/styles/colors';
@import 'src/styles/mixins';
@import '@components/Typography/Typography.module.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.input {
  @extend .typography_number-1;
  border: 0;
  outline: none;
  background: transparent;
  text-align: end;
  overflow-x: auto;
  @include scrollbar-transparent();
  height: 18px;

  &:focus {
    outline: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-moz-placeholder {
    color: $gray-40;
  }

  &::placeholder {
    color: $gray-40;
  }

  &--disabled {
    @extend .input;
    color: $gray-40;
  }
}

.sign {
  margin-left: 6px;
  &--disabled {
    @extend .sign;
    color: $gray-40;
  }
}
