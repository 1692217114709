@import 'src/styles/colors';
@import 'src/styles/elevation';
@import 'src/styles/variables';
@import 'src/styles/zIndex';
@import 'src/styles/mixins';

.row {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  cursor: pointer;

  &:hover {
    .dropdown-header {
      .icon--disabled {
        cursor: default;

        path {
          fill: $gray-20;
        }
      }

      .icon {
        path {
          fill: $gray-60;
        }
      }
    }

    .delete {
      opacity: 1;

      &--hidden {
        cursor: default;
        opacity: 0;
      }
    }
  }

  &--disabled {
    @extend .row;
    cursor: default;

    .percent {
      color: $gray-40;
    }

    &:hover {
      cursor: default;

      .dropdown-header {
        cursor: default;

        path {
          fill: $gray-20;
        }
      }

      .delete {
        cursor: default;
        opacity: 0;

        &--hidden {
          opacity: 0;
        }
      }
    }
  }
}

.dropdown {
  &-header {
    display: flex;
    align-items: center;

    path {
      fill: $gray-20;
      transition: $transition-duration $transition-timing-function;
    }

    &:hover {
      cursor: pointer;
    }

    &--disabled {
      @extend .dropdown-header;
      cursor: default;
    }
  }

  &-menu {
    @include elevation(SelectList);
    @include scrollbar();
    overflow-x: hidden;
    overflow-y: auto;
    width: -moz-max-content;
    width: max-content;
    max-height: 190px;
    background: $white-0;
    border-radius: 0 0 4px 4px;
    position: absolute;
    left: -10px;
    top: 40px;
    z-index: $dropdown-zIndex;
    transition: $transition-duration $transition-timing-function;
    cursor: pointer;

    &-item {
      padding: 10px 20px;

      &:hover {
        color: $gold;
      }
    }
  }
}

.delete {
  position: absolute;
  opacity: 0;
  transition: opacity $transition-duration $transition-timing-function;
  padding-left: 20px;
  top: 8px;

  &--hidden {
    @extend .delete;
    cursor: default;
  }
}

.percent {
  margin-top: 2px;
  text-align: right;
  color: $gray-60;
}

.icon {
  margin-left: 2px;

  &--disabled {
    margin-left: 2px;
  }
}
