/* stylelint-disable */
$elevation-map: (
  button: 0px 1px 2px rgba(18, 18, 18, 0.16),
  dropdown: [0px 2px 2px rgba(18, 18, 18, 0.1),
  0px -1px 1px rgba(26, 26, 26, 0.1)],
  sidemenu: 1px 0px 1px rgba(120, 131, 159, 0.4),
  menu: (
    0px 2px 2px rgba(26, 26, 26, 0.1),
    0px -1px 1px rgba(26, 26, 26, 0.1)
  ),
  SelectList: 0px 1px 2px rgba(18, 18, 18, 0.2),
  topSelectList: 0px -1px 2px rgba(18, 18, 18, 0.2)
);

@mixin elevation($elevation) {
  box-shadow: map-get($elevation-map, $elevation);
}

:export {
  button: map-get($elevation-map, button);
  dropdown: map-get($elevation-map, dropdown);
  sidemenu: map-get($elevation-map, sidemenu);
  menu: map-get($elevation-map, menu);
  SelectList: map-get($elevation-map, SelectList);
  topSelectList: map-get($elevation-map, topSelectList);
}
/* stylelint-enable */
