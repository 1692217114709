@import 'src/styles/variables';

.header {
  padding: 10px;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  &:hover {
    cursor: pointer;
    .close {
      opacity: 1;
    }
  }
}

.close {
  transition: $transition-duration $transition-timing-function;
  opacity: 0;
}
