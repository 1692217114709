@import 'src/styles/colors.scss';

.link {
  &_primary {
    color: $gold;
    cursor: pointer;

    &:hover {
      color: $gold;
    }

    &:active {
      color: $gold;
    }

    &:visited {
      color: $link-visited;
    }
  }

  &_button {
    color: $gray-100;
    cursor: pointer;

    &:hover {
      color: $gray-80;
    }

    &:active {
      color: $gold;
    }
  }

  &_disabled {
    cursor: not-allowed;
    color: $gray-20;

    &:hover {
      color: $gray-20;
    }
  }
}
