/* UI */

// Default page background
$white-0: #ffffff;
// Input background / gray background
$white-5: #f5f5f5;
// Skeletons
$white-10: #eeeeee;
// Disabled state
$gray-20: #dadada;
$gray-40: #b2b2b2;
$gray-50: #b3b3b3;
// Input label / border
$gray-60: #8f8f8f;
$gray-80: #3d3d3d;
// Headings and body text color
$gray-100: #121212;

// Error messages
$red-70: #ff5630;

// Success states
$green-70: #36b37e;

$blue-100: #0052cc;
$blue-70: #0065ff;
$blue-50: #2684ff;
$blue-5: #d4e6ff;
$blue-0: #eaf3ff;

// Visited links
$link-visited: #7b61ff;
// Primary button background in active state
$btn-primary-active: linear-gradient(90deg, #f15a29 0%, #ef4136 100%);
$background: $white-0;
//Background in hover state
$background-hover: #fff9f1;
$active-side-menu-item: #f06516;
$scrollbar-track-color: $white-0;
$scrollbar-thumb-color: rgba(179, 179, 179, 0.5);

/* Exquance */
$fuchsia: #ee2a7b;
$magenta: #da1c5c;
$ruby: #be1e2d;
$red: #ef4136;
$strawberry: #f15a29;
$gold: #f7941d;
$honey: #fbb040;
$black: #000000;
$dark-blue: #434f63;

/* Palettes */

// Exquance colors

// Fuchsia
$fuchsia-180: #990c45;
$fuchsia-160: #bd0f56;
$fuchsia-140: #d21160;
$fuchsia-120: #e9136b;
$fuchsia-100: #ee2a7b;
$fuchsia-80: #f0448b;
$fuchsia-60: #f2619d;
$fuchsia-40: #f580b0;
$fuchsia-20: #f8a3c6;
$fuchsia-10: #fbc9de;

// Magenta
$magenta-180: #801037;
$magenta-160: #9e1444;
$magenta-140: #b0164b;
$magenta-120: #c41953;
$magenta-100: #da1c5c;
$magenta-80: #e42b69;
$magenta-60: #e7437a;
$magenta-40: #ea5d8d;
$magenta-20: #ee7aa1;
$magenta-10: #f29ab8;

// Ruby
$ruby-180: #5f0f17;
$ruby-160: #7d141e;
$ruby-140: #901722;
$ruby-120: #a51a27;
$ruby-100: #be1e2d;
$ruby-80: #d72233;
$ruby-60: #e03a49;
$ruby-40: #e55a66;
$ruby-20: #eb7e87;
$ruby-10: #f1a7ad;

// Red
$red-180: #a0150d;
$red-160: #c61a0f;
$red-140: #dc1d11;
$red-120: #ed271b;
$red-100: #ef4136;
$red-80: #f15b51;
$red-60: #f3776f;
$red-40: #f69690;
$red-20: #f9b8b4;
$red-10: #fcdedc;

// Strawberry
$strawberry-180: #9d2e0b;
$strawberry-160: #c1390d;
$strawberry-140: #d63f0e;
$strawberry-120: #ee4610;
$strawberry-100: #f15a29;
$strawberry-80: #f36e43;
$strawberry-60: #f58460;
$strawberry-40: #f79d80;
$strawberry-20: #f9b8a3;
$strawberry-10: #fcd6ca;

// Gold
$gold-180: #9d5905;
$gold-160: #c26e06;
$gold-140: #d87a07;
$gold-120: #f08708;
$gold-100: #f7941d;
$gold-80: #f8a138;
$gold-60: #f9af55;
$gold-40: #fabe75;
$gold-20: #fbcf98;
$gold-10: #fde1bf;

// Honey
$honey-180: #b66e05;
$honey-160: #e08805;
$honey-140: #f99706;
$honey-120: #faa321;
$honey-100: #fbb040;
$honey-80: #fcbd5f;
$honey-60: #fdcb81;
$honey-40: #fedba7;
$honey-20: #ffebcc;
$honey-10: #fff3e0;

// Dark-blue
$dark-blue-180: #212732;
$dark-blue-160: #2c3441;
$dark-blue-140: #323c4b;
$dark-blue-120: #3a4556;
$dark-blue-100: #434f63;
$dark-blue-80: #4c5970;
$dark-blue-60: #56657f;
$dark-blue-40: #617290;
$dark-blue-20: #71829f;
$dark-blue-10: #8695ad;

// Base colors

// Orange
$orange-180: #964000;
$orange-160: #ba4f00;
$orange-140: #cf5800;
$orange-120: #e66200;
$orange-100: #ff6d00;
$orange-80: #ff7c1a;
$orange-60: #ff8c36;
$orange-40: #ff9e55;
$orange-30: #ff9e2b;
$orange-20: #ffb177;
$orange-10: #ffc69c;

// Yellow
$yellow-180: #967f00;
$yellow-160: #ba9d00;
$yellow-140: #cfae00;
$yellow-120: #e6c100;
$yellow-100: #ffd600;
$yellow-80: #ffda1a;
$yellow-60: #ffdf36;
$yellow-40: #ffe455;
$yellow-20: #ffe977;
$yellow-10: #ffef9c;

// Green
$green-180: #005035;
$green-160: #006342;
$green-140: #006e49;
$green-120: #007a51;
$green-100: #00875a;
$green-80: #009563;
$green-60: #00a46d;
$green-40: #00b478;
$green-20: #00c684;
$green-10: #00da91;

// Light-blue
$light-blue-180: #00558b;
$light-blue-160: #0069ab;
$light-blue-140: #0075be;
$light-blue-120: #0082d3;
$light-blue-100: #0091ea;
$light-blue-80: #029fff;
$light-blue-60: #1ca9ff;
$light-blue-40: #38b4ff;
$light-blue-20: #57c0ff;
$light-blue-10: #79cdff;

// Blue
$blue-180: #003179;
$blue-160: #003c95;
$blue-140: #0043a6;
$blue-120: #004ab8;
$blue-100: #0052cc;
$blue-80: #005ae0;
$blue-60: #0063f6;
$blue-40: #1070ff;
$blue-20: #2b80ff;
$blue-10: #4992ff;

// Purple
$purple-180: #650096;
$purple-160: #7c00ba;
$purple-140: #8a00cf;
$purple-120: #9900e6;
$purple-100: #aa00ff;
$purple-80: #b31aff;
$purple-60: #dc36ff;
$purple-40: #c655ff;
$purple-20: #d177ff;
$purple-10: #de9cff;

/* stylelint-disable */
:export {
  whiteColor: $white-0;
  white5Color: $white-5;
  white10Color: $white-10;
  gray20Color: $gray-20;
  gray40Color: $gray-40;
  gray60Color: $gray-60;
  gray80Color: $gray-80;
  gray100Color: $gray-100;
  goldColor: $gold;
  red70Color: $red-70;
  green70Color: $green-70;
  blue0Color: $blue-0;
  blue5Color: $blue-5;
  blue50Color: $blue-50;
  blue70Color: $blue-70;
  blue100Color: $blue-100;
  linkVisited: $link-visited;
  background: $background;
  backgroundHover: $background-hover;
  fuchsia: $fuchsia;
  magenta: $magenta;
  ruby: $ruby;
  red: $red;
  strawberry: $strawberry;
  gold: $gold;
  honey: $honey;
  black: $black;
  darkBlue: $dark-blue;
  activeSideMenuItem: $active-side-menu-item;
  palettesFuchsia180: $fuchsia-180;
  palettesFuchsia160: $fuchsia-160;
  palettesFuchsia140: $fuchsia-140;
  palettesFuchsia120: $fuchsia-120;
  palettesFuchsia100: $fuchsia-100;
  palettesFuchsia80: $fuchsia-80;
  palettesFuchsia60: $fuchsia-60;
  palettesFuchsia40: $fuchsia-40;
  palettesFuchsia20: $fuchsia-20;
  palettesFuchsia10: $fuchsia-10;
  palettesMagenta180: $magenta-180;
  palettesMagenta160: $magenta-160;
  palettesMagenta140: $magenta-140;
  palettesMagenta120: $magenta-120;
  palettesMagenta100: $magenta-100;
  palettesMagenta80: $magenta-80;
  palettesMagenta60: $magenta-60;
  palettesMagenta40: $magenta-40;
  palettesMagenta20: $magenta-20;
  palettesMagenta10: $magenta-10;
  palettesRuby180: $ruby-180;
  palettesRuby160: $ruby-160;
  palettesRuby140: $ruby-140;
  palettesRuby120: $ruby-120;
  palettesRuby100: $ruby-100;
  palettesRuby80: $ruby-80;
  palettesRuby60: $ruby-60;
  palettesRuby40: $ruby-40;
  palettesRuby20: $ruby-20;
  palettesRuby10: $ruby-10;
  palettesRed180: $red-180;
  palettesRed160: $red-160;
  palettesRed140: $red-140;
  palettesRed120: $red-120;
  palettesRed100: $red-100;
  palettesRed80: $red-80;
  palettesRed60: $red-60;
  palettesRed40: $red-40;
  palettesRed20: $red-20;
  palettesRed10: $red-10;
  palettesStrawberry180: $strawberry-180;
  palettesStrawberry160: $strawberry-160;
  palettesStrawberry140: $strawberry-140;
  palettesStrawberry120: $strawberry-120;
  palettesStrawberry100: $strawberry-100;
  palettesStrawberry80: $strawberry-80;
  palettesStrawberry60: $strawberry-60;
  palettesStrawberry40: $strawberry-40;
  palettesStrawberry20: $strawberry-20;
  palettesStrawberry10: $strawberry-10;
  palettesGold180: $gold-180;
  palettesGold160: $gold-160;
  palettesGold140: $gold-140;
  palettesGold120: $gold-120;
  palettesGold100: $gold-100;
  palettesGold80: $gold-80;
  palettesGold60: $gold-60;
  palettesGold40: $gold-40;
  palettesGold20: $gold-20;
  palettesGold10: $gold-10;
  palettesHoney180: $honey-180;
  palettesHoney160: $honey-160;
  palettesHoney140: $honey-140;
  palettesHoney120: $honey-120;
  palettesHoney100: $honey-100;
  palettesHoney80: $honey-80;
  palettesHoney60: $honey-60;
  palettesHoney40: $honey-40;
  palettesHoney20: $honey-20;
  palettesHoney10: $honey-10;
  palettesDarkBlue180: $dark-blue-180;
  palettesDarkBlue160: $dark-blue-160;
  palettesDarkBlue140: $dark-blue-140;
  palettesDarkBlue120: $dark-blue-120;
  palettesDarkBlue100: $dark-blue-100;
  palettesDarkBlue80: $dark-blue-80;
  palettesDarkBlue60: $dark-blue-60;
  palettesDarkBlue40: $dark-blue-40;
  palettesDarkBlue20: $dark-blue-20;
  palettesDarkBlue10: $dark-blue-10;
  palettesRed180: $red-180;
  palettesRed160: $red-160;
  palettesRed140: $red-140;
  palettesRed120: $red-120;
  palettesRed100: $red-100;
  palettesRed80: $red-80;
  palettesRed60: $red-60;
  palettesRed40: $red-40;
  palettesRed20: $red-20;
  palettesRed10: $red-10;
  palettesOrange180: $orange-180;
  palettesOrange160: $orange-160;
  palettesOrange140: $orange-140;
  palettesOrange120: $orange-120;
  palettesOrange100: $orange-100;
  palettesOrange80: $orange-80;
  palettesOrange60: $orange-60;
  palettesOrange40: $orange-40;
  palettesOrange30: $orange-30;
  palettesOrange20: $orange-20;
  palettesOrange10: $orange-10;
  palettesYellow180: $yellow-180;
  palettesYellow160: $yellow-160;
  palettesYellow140: $yellow-140;
  palettesYellow120: $yellow-120;
  palettesYellow100: $yellow-100;
  palettesYellow80: $yellow-80;
  palettesYellow60: $yellow-60;
  palettesYellow40: $yellow-40;
  palettesYellow20: $yellow-20;
  palettesYellow10: $yellow-10;
  palettesGreen180: $green-180;
  palettesGreen160: $green-160;
  palettesGreen140: $green-140;
  palettesGreen120: $green-120;
  palettesGreen100: $green-100;
  palettesGreen80: $green-80;
  palettesGreen60: $green-60;
  palettesGreen40: $green-40;
  palettesGreen20: $green-20;
  palettesGreen10: $green-10;
  palettesLightBlue180: $light-blue-180;
  palettesLightBlue160: $light-blue-160;
  palettesLightBlue140: $light-blue-140;
  palettesLightBlue120: $light-blue-120;
  palettesLightBlue100: $light-blue-100;
  palettesLightBlue80: $light-blue-80;
  palettesLightBlue60: $light-blue-60;
  palettesLightBlue40: $light-blue-40;
  palettesLightBlue20: $light-blue-20;
  palettesLightBlue10: $light-blue-10;
  palettesBlue180: $blue-180;
  palettesBlue160: $blue-160;
  palettesBlue140: $blue-140;
  palettesBlue120: $blue-120;
  palettesBlue100: $blue-100;
  palettesBlue80: $blue-80;
  palettesBlue60: $blue-60;
  palettesBlue40: $blue-40;
  palettesBlue20: $blue-20;
  palettesBlue10: $blue-10;
  palettesPurple180: $purple-180;
  palettesPurple160: $purple-160;
  palettesPurple140: $purple-140;
  palettesPurple120: $purple-120;
  palettesPurple100: $purple-100;
  palettesPurple80: $purple-80;
  palettesPurple60: $purple-60;
  palettesPurple40: $purple-40;
  palettesPurple20: $purple-20;
  palettesPurple10: $purple-10;
}
