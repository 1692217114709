@import 'src/styles/colors';
@import 'src/styles/variables';

.toggle {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $gray-60;
  transition: $transition-duration;
  border-radius: 60px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: $transition-duration;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: $gold;
}

input:disabled + .slider {
  background-color: $gray-20;
  cursor: initial;
}

input:checked + .slider:before {
  transform: translateX(16px);
}
