@import 'src/styles/colors';
@import 'src/styles/elevation';
@import 'src/styles/variables';
@import 'src/styles/zIndex';
@import 'src/styles/mixins';
@import '@components/Typography/Typography.module.scss';

.row {
  position: relative;

  &--disabled {
    position: relative;
  }

  &:hover {
    .dropdown-header {
      .icon {
        path {
          fill: $gray-60;
        }
      }
    }

    .delete {
      opacity: 1;

      &--hidden {
        cursor: default;
        opacity: 0;
      }
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: 110px 180px 120px 180px 100px;
  margin-right: 20px;
  gap: 30px;
  align-items: flex-start;
}

.delete {
  position: absolute;
  opacity: 0;
  transition: opacity $transition-duration $transition-timing-function;
  padding-left: 20px;
  top: 8px;
  right: -86px;

  &--hidden {
    @extend .delete;
    cursor: default;
  }
}

.icon {
  margin-left: 2px;
}

.input {
  @extend .typography_body-4;
  padding: 0;
  border: 0;
  outline: none;
  background: transparent;
  margin-top: 8px;

  &--disabled {
    @extend .typography_body-4;
    padding: 0;
    color: $gray-40;
    border: 0;
    outline: none;
    background: transparent;
    -webkit-text-fill-color: $gray-40;
    margin-top: 8px;
  }

  &:focus {
    outline: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-moz-placeholder {
    &::first-letter {
      text-transform: capitalize;
    }

    color: $gray-40;
  }

  &::placeholder {
    &::first-letter {
      text-transform: capitalize;
    }

    color: $gray-40;
  }
}

.sign {
  margin-left: 6px;
}
