/* Proxima Nova */
@font-face {
  font-display: swap;
  font-family: 'Proxima Nova';
  src: url('@componentsStyles/fonts/ProximaNova/ProximaNova-Black.eot');
  src:
    local('Proxima Nova Black'),
    local('ProximaNova-Black'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Black.eot')
      format('embedded-opentype'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Black.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Black.woff')
      format('woff'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Black.ttf')
      format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Proxima Nova';
  src: url('@componentsStyles/fonts/ProximaNova/ProximaNova-Extrabld.eot');
  src:
    local('Proxima Nova Extrabold'),
    local('ProximaNova-Extrabld'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Extrabld.eot')
      format('embedded-opentype'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Extrabld.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Extrabld.woff')
      format('woff'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Extrabld.ttf')
      format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Proxima Nova';
  src: url('@componentsStyles/fonts/ProximaNova/ProximaNova-SemiboldIt.eot');
  src:
    local('Proxima Nova Semibold Italic'),
    local('ProximaNova-SemiboldIt'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-SemiboldIt.eot')
      format('embedded-opentype'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-SemiboldIt.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-SemiboldIt.woff')
      format('woff'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-SemiboldIt.ttf')
      format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Proxima Nova';
  src: url('@componentsStyles/fonts/ProximaNova/ProximaNovaT-Thin.eot');
  src:
    local('Proxima Nova Thin'),
    local('ProximaNovaT-Thin'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNovaT-Thin.eot')
      format('embedded-opentype'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNovaT-Thin.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNovaT-Thin.woff')
      format('woff'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNovaT-Thin.ttf')
      format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Proxima Nova';
  src: url('@componentsStyles/fonts/ProximaNova/ProximaNova-Light.eot');
  src:
    local('Proxima Nova Light'),
    local('ProximaNova-Light'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Light.eot')
      format('embedded-opentype'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Light.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Light.woff')
      format('woff'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Light.ttf')
      format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Proxima Nova';
  src: url('@componentsStyles/fonts/ProximaNova/ProximaNova-BlackIt.eot');
  src:
    local('Proxima Nova Black Italic'),
    local('ProximaNova-BlackIt'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-BlackIt.eot')
      format('embedded-opentype'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-BlackIt.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-BlackIt.woff')
      format('woff'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-BlackIt.ttf')
      format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Proxima Nova';
  src: url('@componentsStyles/fonts/ProximaNova/ProximaNova-BoldIt.eot');
  src:
    local('Proxima Nova Bold Italic'),
    local('ProximaNova-BoldIt'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-BoldIt.eot')
      format('embedded-opentype'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-BoldIt.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-BoldIt.woff')
      format('woff'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-BoldIt.ttf')
      format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Proxima Nova';
  src: url('@componentsStyles/fonts/ProximaNova/ProximaNova-ThinIt.eot');
  src:
    local('Proxima Nova Thin Italic'),
    local('ProximaNova-ThinIt'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-ThinIt.eot')
      format('embedded-opentype'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-ThinIt.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-ThinIt.woff')
      format('woff'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-ThinIt.ttf')
      format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Proxima Nova';
  src: url('@componentsStyles/fonts/ProximaNova/ProximaNova-Bold.eot');
  src:
    local('Proxima Nova Bold'),
    local('ProximaNova-Bold'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Bold.eot')
      format('embedded-opentype'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Bold.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Bold.woff')
      format('woff'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Bold.ttf')
      format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Proxima Nova';
  src: url('@componentsStyles/fonts/ProximaNova/ProximaNova-RegularIt.eot');
  src:
    local('Proxima Nova Regular Italic'),
    local('ProximaNova-RegularIt'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-RegularIt.eot')
      format('embedded-opentype'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-RegularIt.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-RegularIt.woff')
      format('woff'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-RegularIt.ttf')
      format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Proxima Nova';
  src: url('@componentsStyles/fonts/ProximaNova/ProximaNova-ExtrabldIt.eot');
  src:
    local('Proxima Nova Extrabold Italic'),
    local('ProximaNova-ExtrabldIt'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-ExtrabldIt.eot')
      format('embedded-opentype'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-ExtrabldIt.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-ExtrabldIt.woff')
      format('woff'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-ExtrabldIt.ttf')
      format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Proxima Nova';
  src: url('@componentsStyles/fonts/ProximaNova/ProximaNova-Regular.eot');
  src:
    local('Proxima Nova Regular'),
    local('ProximaNova-Regular'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Regular.eot')
      format('embedded-opentype'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Regular.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Regular.woff')
      format('woff'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Regular.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Proxima Nova';
  src: url('@componentsStyles/fonts/ProximaNova/ProximaNova-LightIt.eot');
  src:
    local('Proxima Nova Light Italic'),
    local('ProximaNova-LightIt'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-LightIt.eot')
      format('embedded-opentype'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-LightIt.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-LightIt.woff')
      format('woff'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-LightIt.ttf')
      format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Proxima Nova';
  src: url('@componentsStyles/fonts/ProximaNova/ProximaNova-Semibold.eot');
  src:
    local('Proxima Nova Semibold'),
    local('ProximaNova-Semibold'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Semibold.eot')
      format('embedded-opentype'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Semibold.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Semibold.woff')
      format('woff'),
    url('@componentsStyles/fonts/ProximaNova/ProximaNova-Semibold.ttf')
      format('truetype');
  font-weight: 600;
  font-style: normal;
}

/* IBM Plex Mono */
@font-face {
  font-display: swap;
  font-family: 'IBM Plex Mono';
  src:
    local('IBM Plex Mono Bold'),
    local('IBMPlexMono-Bold'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-Bold.eot')
      format('embedded-opentype'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-Bold.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-Bold.woff')
      format('woff'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-Bold.ttf')
      format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Mono';
  src:
    local('IBM Plex Mono ExtraLight'),
    local('IBMPlexMono-ExtraLight'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-ExtraLight.eot')
      format('embedded-opentype'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-ExtraLight.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-ExtraLight.woff')
      format('woff'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-ExtraLight.ttf')
      format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Mono';
  src:
    local('IBM Plex Mono Light'),
    local('IBMPlexMono-Light'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-Light.eot')
      format('embedded-opentype'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-Light.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-Light.woff')
      format('woff'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-Light.ttf')
      format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Mono';
  src:
    local('IBM Plex Mono Medium'),
    local('IBMPlexMono-Medium'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-Medium.eot')
      format('embedded-opentype'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-Medium.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-Medium.woff')
      format('woff'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-Medium.ttf')
      format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Mono';
  src:
    local('IBM Plex Mono Regular'),
    local('IBMPlexMono-Regular'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-Regular.eot')
      format('embedded-opentype'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-Regular.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-Regular.woff')
      format('woff'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-Regular.ttf')
      format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Mono';
  src:
    local('IBM Plex Mono SemiBold'),
    local('IBMPlexMono-SemiBold'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-SemiBold.eot')
      format('embedded-opentype'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-SemiBold.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-SemiBold.woff')
      format('woff'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-SemiBold.ttf')
      format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Mono';
  src:
    local('IBM Plex Mono Thin'),
    local('IBMPlexMono-Thin'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-Thin.eot')
      format('embedded-opentype'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-Thin.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-Thin.woff')
      format('woff'),
    url('@componentsStyles/fonts/IBMPlexMono/IBMPlexMono-Thin.ttf')
      format('truetype');
  font-weight: 100;
  font-style: normal;
}

// JetBrains Mono

@font-face {
  font-display: swap;
  font-family: 'JetBrains Mono';
  src:
    local('JetBrains Mono Regular'),
    local('IBMPlexMono-Regular'),
    url('@componentsStyles/fonts/JetBrainsMono/JetBrainsMono-Regular.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/JetBrainsMono/JetBrainsMono-Regular.ttf')
      format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'JetBrains Mono';
  src:
    local('JetBrains Mono Medium'),
    local('IBMPlexMono-Medium'),
    url('@componentsStyles/fonts/JetBrainsMono/JetBrainsMono-Medium.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/JetBrainsMono/JetBrainsMono-Medium.ttf')
      format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'JetBrains Mono';
  src:
    local('JetBrains Mono SemiBold'),
    local('IBMPlexMono-SemiBold'),
    url('@componentsStyles/fonts/JetBrainsMono/JetBrainsMono-SemiBold.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/JetBrainsMono/JetBrainsMono-SemiBold.ttf')
      format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'JetBrains Mono';
  src:
    local('JetBrains Mono Bold'),
    local('IBMPlexMono-Bold'),
    url('@componentsStyles/fonts/JetBrainsMono/JetBrainsMono-Bold.woff2')
      format('woff2'),
    url('@componentsStyles/fonts/JetBrainsMono/JetBrainsMono-Bold.ttf')
      format('truetype');
  font-weight: 700;
  font-style: normal;
}

* {
  font-family: 'Proxima Nova', sans-serif;
  font-style: normal;

  &:first-letter {
    text-transform: uppercase;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.typography {
  white-space: pre-line;

  &_h0 {
    font-weight: 900;
    font-size: 200px;
    line-height: 244px;
    letter-spacing: 0.02em;
  }

  &_h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
  }

  &_h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
  }

  &_h3 {
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
  }

  &_h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
  }

  &_h5 {
    //font-family: 'Proxima Nova Lt', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  &_body-1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  &_body-2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  &_body-3 {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
  }

  &_body-4 {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }

  &_body-5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }

  &_body-6 {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
  }

  &_link {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: underline;
  }

  &_label-1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  &_label-2 {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  &_system {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    &-heading {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
    }

    &-heading-2 {
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.2px;
      text-transform: uppercase;
    }
  }

  &_button {
    &-primary,
    &-secondary {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }

    &-third {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
  }

  &_chevron {
    font-weight: 700;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    white-space: nowrap;
  }

  &_superscript {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-transform: uppercase;
  }

  &_superscript-2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
  }

  &_number-1 {
    font-family: 'JetBrains Mono', monospace;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }

  &_number-2 {
    font-family: 'JetBrains Mono', monospace;
    font-weight: 600;
    //font-size: 14px;
    line-height: 18px;
  }

  &_number-3 {
    font-family: 'JetBrains Mono', monospace;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  }

  &_number-4 {
    font-family: 'JetBrains Mono', monospace;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
  }

  &_header {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }
}
