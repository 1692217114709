@import '@componentsStyles/colors.scss';
@import '@componentsStyles/variables.scss';
@import '../../Header.module.scss';

.wrapper {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  padding: 4px 12px;
  margin: -4px 4px;

  &--active {
    @extend .wrapper;
    border-radius: 2px;
    background: rgba(247, 148, 29, 0.2);
    gap: 8px;
    color: $active-side-menu-item;
    cursor: pointer;

    &:hover {
      path {
        fill-opacity: 1;
      }
    }
  }
}

.link {
  @extend .link;
  margin: 0;
  color: inherit;
}

.icon {
  path {
    fill: $active-side-menu-item;
    fill-opacity: 0.5;
    transition: $transition-duration $transition-timing-function;
  }
}
