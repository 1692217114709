@import '@xq/ui-kit/lib/styles/breakpoints.scss';
@import '@xq/ui-kit/lib/styles/mixins.scss';

.heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.subheading {
  display: flex;
  margin-top: 8px;
}

.table {
  grid-area: table;

  &-status {
    display: flex;
    align-items: center;
    margin-left: -16px;
  }

  &-user {
    display: flex;
  }

  &-username {
    display: flex;
    align-items: center;
  }

  &-grid {
    display: grid;
    grid-template-areas:
      'card'
      'search'
      'table';
    gap: 20px;
    @media (min-width: $md) {
      grid-template-columns: 1fr 304px;
      gap: 20px 50px;
      grid-template-areas:
        'search .'
        'table card';
      align-items: flex-start;
    }
    @media (min-width: $lg) {
      gap: 20px 166px;
    }
  }
}

.statistics {
  @include scrollbar();
  margin-bottom: 40px;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  margin-right: -20px;
}

.buttons {
  display: flex;
  align-items: center;
  margin-left: 40px;
}

.card {
  grid-area: card;
  margin-bottom: 40px;
  max-width: -moz-fit-content;
  max-width: fit-content;

  @media (min-width: $md) {
    margin-bottom: 0;
  }
}

.search {
  grid-area: search;
}

.features {
  flex-wrap: wrap;
  display: flex;
}
