@import '@componentsStyles/colors.scss';
@import '@componentsStyles/zIndex.scss';
@import '@componentsStyles/elevation.scss';
@import '@componentsStyles/mixins.scss';
@import 'src/styles/variables';

$max-width: 572px;

.dropdown {
  max-width: $max-width;
  @include elevation(SelectList);
  @include scrollbar();
  border-radius: 0 0 4px 4px;
  background: $white-0;
  overflow-x: hidden;
  overflow-y: auto;
  width: -moz-max-content;
  width: max-content;
  display: inline-block;
  max-height: 500px;
  background: $white-0;
  padding: 10px 0;
  z-index: $dropdown-zIndex;
  position: absolute;
  top: 28px;
  min-width: 175px;

  &-item {
    display: flex;
    min-height: 40px;
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    &-title {
      color: $gray-100;
      font-family:
        Proxima Nova,
        sans-serif;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px;
      letter-spacing: 0.2px;
      text-transform: uppercase;
    }
  }
}

.filters {
  padding-left: 10px;
}

.filter {
  display: flex;
  padding: 10px 20px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.toggle {
  display: flex;
  align-items: center;
}

.block {
  transition-duration: 0.3s;
  transition-timing-function: $transition-timing-function;
  transition: opacity $transition-duration $transition-timing-function;

  &-visible {
    //@extend .block;
    visibility: visible;
    height: auto;
    max-height: -moz-fit-content;
    max-height: fit-content;
    display: initial;
  }

  &-hidden {
    //@extend .block;
    visibility: hidden;
    height: 0;
    opacity: 0;
    max-height: 0;
    display: none;
  }
}
