@import 'src/styles/colors';

.superscript {
  display: inline-block;
  color: $gray-100;
  white-space: nowrap;
}

.content {
  display: inline-block;

  &--loading {
    display: inline-block;
    width: 42px;
    height: 12px;
    vertical-align: 12px;
  }
}

.sign {
  display: inline-block;
  vertical-align: 10px;
  margin-left: 4px;
}
