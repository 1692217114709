@import '@componentsStyles/colors';
@import '@componentsStyles/breakpoints';
@import '@componentsStyles/variables';
@import '@componentsStyles/elevation';
@import '../../Header.module.scss';

.name {
  margin-left: 8px;
  margin-right: 2px;
}

.flex {
  display: flex;
}

.link {
  @extend .link;
  margin: 40px 40px 20px 40px;
  display: flex;
  cursor: pointer;

  &--small {
    @extend .link;
    margin: 20px 40px;
    display: block;
  }

  &--disabled {
    @extend .link;
    cursor: default;
    &:hover,
    &:active {
      path {
        fill: $gray-100;
      }
      color: $gray-100;
    }
  }
}

.divider {
  margin: 20px 40px;
}
