.wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 2px;

  > div {
    background-color: rgba(255, 255, 255, 0.8);
    height: 24px;
    transition: background-color 200ms linear;

    &:first-child {
      border-radius: 2px 2px 0 0;
      padding: 8px 4px 5px;
    }

    &:last-child {
      border-radius: 0 0 2px 2px;
      padding: 5px 4px 8px;
    }

    &:hover {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 1);
    }
  }
}
