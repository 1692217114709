@import '@componentsStyles/zIndex.scss';

.wrap {
  width: 500px;
  overflow: hidden;
  position: relative;
  min-height: 28px;
}

.container-inner {
  overflow: hidden;
  position: relative;
}

.content {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  width: -moz-max-content;
  width: max-content;
  position: relative;
  transition: left 0.3s ease;
  min-width: 500px;

  &--hidden {
    @extend .content;
    opacity: 0;
  }
}

.scroll-left,
.scroll-right {
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  width: 60px;
  z-index: $zIndex-1;
}

.scroll-left {
  left: 0;
  background: linear-gradient(
    90deg,
    #f5f5f5 41.67%,
    rgba(245, 245, 245, 0) 100%
  );
  justify-content: flex-start;
}

.scroll-right {
  right: 0;
  background: linear-gradient(
    270deg,
    #f5f5f5 41.67%,
    rgba(245, 245, 245, 0) 100%
  );
  justify-content: flex-end;
}
