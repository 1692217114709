@import 'src/styles/colors';
@import 'src/styles/elevation';
@import 'src/styles/variables';

.card {
  position: relative;
  color: $gray-100;

  &--disabled {
    color: $gray-40;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover .add-icon--hover {
    opacity: 1;
  }
}

.is_trial {
  margin-top: 40px;
  display: flex;
  align-items: center;
}

.feature {
  display: flex;
  align-items: center;
}

.label {
  margin-top: 20px;
  height: 20px;
  overflow: hidden;
  display: -webkit-box;
  display: -moz-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.add-icon {
  position: absolute;
  top: 22px;
  right: 12px;

  &--hover {
    position: absolute;
    top: 18px;
    right: 8px;
    opacity: 0;
  }
}

.card:hover .add-icon--hover {
  opacity: 1;
}
