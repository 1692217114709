@import 'src/styles/colors';
@import 'src/styles/elevation';
@import 'src/styles/variables';
@import 'src/styles/mixins';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  transition: $transition-duration $transition-timing-function;
}

.details {
  position: absolute;
  top: 24px;
  right: 12px;

  path {
    fill: $gray-40;
    transition: $transition-duration $transition-timing-function;
  }
}

.row {
  display: flex;
  align-items: center;
}

.grid {
  display: flex;
  overflow-x: auto;
  @include scrollbar-transparent();
  padding-bottom: 20px;
}

.card {
  position: relative;
  padding: 20px 40px 0 40px;

  &:hover {
    cursor: pointer;
    .header {
      color: $active-side-menu-item;
      path {
        fill: $gray-100;
      }
    }
  }

  &--loading {
    position: relative;
    padding: 20px 40px 0 40px;

    path {
      fill: $gray-20;
    }
  }
}

.payments {
  display: flex;
  align-items: center;
}

.payment {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.line {
  border-left: 0.3px solid $gray-100;
  height: 26px;
  margin-left: 20px;
}
