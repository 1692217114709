@import 'src/styles/colors';

.card {
  position: relative;
  color: $gray-100;

  &--disabled {
    position: relative;
    color: $gray-40;
  }
}

.icon {
  position: absolute;
  top: 22px;
  right: 12px;
}
