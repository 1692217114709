@import 'src/styles/colors';
@import 'src/styles/variables';

.chart {
  display: grid;
  grid-template-columns: 234px 1fr;
  gap: 20px;
  align-items: center;
  position: relative;

  &-svg {
    position: relative;
  }
}

.info {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.description {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-color {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    margin-left: 10px;
  }

  &-block {
    display: grid;
    grid-gap: 24px;
    width: -moz-max-content;
    width: max-content;
  }
}

path {
  transform-origin: center;
}
