.wrapper {
  position: relative;
}

.mapWrapper {
  position: relative;
  height: 100%;
}

.defaultMapContainer {
  height: 500px;
  width: 100%;
}

.controls {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 10px;
  gap: 10px;
  right: 10px;
  z-index: 10;
}

.mapLoaderWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &Main {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.relativeCover {
    height: 100%;
  }
}

.mapLoaderWrapperMain {
  height: 100%;
}
