@import 'src/styles/colors.scss';
@import 'src/styles/zIndex.scss';
@import 'src/styles/variables.scss';
@import '@components/Typography/Typography.module.scss';

$margin-left: 10px;
$margin-s: 4px;

.form {
  position: relative;
  padding-bottom: 20px;
  overflow: hidden;
}

.input {
  @extend .typography_label-1;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  outline: none;
  border: 0;
  border-bottom: 1px solid $gray-60;
  border-radius: 0;
  padding: 26px 0 $margin-left $margin-left;
  color: $gray-100;
  background: none;
  z-index: $zIndex-1;
  transition: $transition-duration $transition-timing-function;

  &:disabled {
    background: $white-5;
    cursor: not-allowed;
    border-radius: 4px 4px 0 0;
    z-index: 0;
  }

  &:hover {
    color: $gray-80;
    border-color: $gray-80;
    cursor: pointer;
  }

  &:disabled:hover {
    cursor: not-allowed;
    border-bottom: 1px solid $gray-60;
  }

  &:focus {
    border-bottom: 1px solid $gold;
    z-index: $zIndex-5;
  }

  &_error {
    border-bottom: 1px solid $red-70;

    &:hover,
    &:focus {
      border-bottom: 1px solid $red-70;
    }
  }

  &-short {
    padding-right: 40px;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
  /* Chrome */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.label {
  @extend .typography_label-1;
  position: absolute;
  bottom: 30px;
  transition: $transition-duration $transition-timing-function;
  left: 0;
  color: $gray-60;
  padding: $margin-s $margin-s $margin-s $margin-left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.input:not(:-moz-placeholder-shown) + .label {
  font-size: 12px;
  line-height: 16px;
  bottom: 55px;
  opacity: 1;
}

.input:focus + .label,
.input:not(:placeholder-shown) + .label {
  font-size: 12px;
  line-height: 16px;
  bottom: 55px;
  opacity: 1;
}

.input:hover:disabled + .label {
  color: $gray-60;
}

.input:hover + .label {
  color: $gray-80;
}

.input:focus + .label {
  color: $gold;
}

.input_error:hover + .label,
.input_error:focus + .label {
  color: $red-70;
}

.required-symbol {
  color: $red-70;
  margin-left: $margin-s;
}

.error-message {
  position: absolute;
  margin-top: $margin-s;
  margin-left: $margin-left;
  display: block;
}

.icon {
  position: absolute;
  cursor: pointer;
  right: $margin-left;
  bottom: 30px;
  fill: $gray-60;
  z-index: $zIndex-5;
  transition: $transition-duration $transition-timing-function;

  &-enter {
    @extend .icon;
    opacity: 0;
    visibility: hidden;
  }
}

.input:hover:disabled ~ .icon {
  fill: $gray-60;
}

.input:hover ~ .icon {
  fill: $gray-80;
}

.input:focus ~ .icon {
  fill: $gold;
}
.input_error:hover ~ .icon,
.input_error:focus ~ .icon {
  fill: $red-70;
}

.input:focus-visible,
input:focus ~ .icon-enter {
  opacity: 100%;
  visibility: visible;
}
