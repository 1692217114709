@import '@componentsStyles/breakpoints';
@import '@componentsStyles/utils';

$grid-columns: 12;

$margin-sm: 20px;
$margin-md: 20px;
$margin-lg: 40px;

/* Use container before the row-10 to make layout with one column offset */
.container {
  $columns: 12;
  margin: 0 calc(percentage(divide(1, $columns)) - 2px);
}

@mixin make-col($size: false, $columns) {
  @if $size {
    flex: 0 0 auto;
    width: percentage(divide($size, $columns));
  } @else {
    flex: 1 1 0;
    max-width: 100%;
  }
}

@mixin make-col-auto() {
  flex: 0 0 auto;
  width: auto;
}

@mixin make-grid-columns($columns, $breakpoints: $grid-breakpoints) {
  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint);

    @include media-breakpoint-up($breakpoint) {
      .col#{$infix} {
        flex: 1 0 0;
      }

      .col#{$infix}-auto {
        @include make-col-auto();
      }

      @if $columns > 0 {
        @for $i from 1 through $columns {
          .col#{$infix}-#{$i} {
            @include make-col($i, $columns);
          }
        }
      }
    }
  }
}

/* Row is indented by margin plus 1/2 gutter (the second is added by a column) */
@mixin make-row() {
  display: flex;
  flex-wrap: wrap;

  --gutter-x: #{$gutter-sm};
  margin-right: calc(#{$margin-sm} - #{$gutter-sm}/ 2);
  margin-left: calc(#{$margin-sm} - #{$gutter-sm}/ 2);

  @include media-breakpoint-up(md) {
    --gutter-x: #{$gutter-md};
    margin-right: calc(#{$margin-md} - #{$gutter-md}/ 2);
    margin-left: calc(#{$margin-md} - #{$gutter-md}/ 2);
  }

  @include media-breakpoint-up(lg) {
    --gutter-x: #{$gutter-lg};
    margin-right: calc(#{$margin-lg} - #{$gutter-lg}/ 2);
    margin-left: calc(#{$margin-lg} - #{$gutter-lg}/ 2);
  }
}

/* Each column is indented by 1/2 gutter */
@mixin make-col-ready() {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding: 0 calc(var(--gutter-x) * 0.5);
}

.row {
  @include make-row();

  > * {
    @include make-col-ready();
  }

  @include make-grid-columns($grid-columns);
}

.row-10 {
  @include make-row();

  > * {
    @include make-col-ready();
  }

  @include make-grid-columns(10);
}
