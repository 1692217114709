/* Important! This needs to have `position: relative` parent */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image {
  margin-bottom: 40px;
  width: 251px;
  height: 162px;
}

.button {
  margin-top: 40px;
}

.text {
  text-align: center;
}
