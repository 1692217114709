@import 'src/styles/colors';
@import 'src/styles/elevation';
@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/zIndex';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  transition: $transition-duration $transition-timing-function;
}

.details {
  position: absolute;
  top: 24px;
  right: 12px;

  path {
    fill: $gray-40;
    transition: $transition-duration $transition-timing-function;
  }
}

.warning-icon {
  position: absolute;
  top: 24px;
  left: 12px;
}

.row {
  display: flex;
  align-items: center;
}

.card {
  position: relative;
  padding: 20px 40px 0 40px;

  &:hover {
    cursor: pointer;

    .header {
      color: $active-side-menu-item;
    }

    .details {
      path {
        fill: $gray-100;
      }
    }
  }

  &--loading {
    position: relative;
    padding: 20px 40px 0 40px;
  }
}

.scroll-container {
  @include scrollbar-transparent();
  padding-bottom: 20px;
}

.versions {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 4px;
}

.dropdown {
  padding-inline-start: 0;
  position: absolute;
  left: 0;
  list-style: none;
  z-index: $dropdown-zIndex;
  overflow-y: auto;
  width: -moz-max-content;
  width: max-content;
  max-width: 300px;
  max-height: 220px;
  background: $white-0;
  @include scrollbar();
  padding: 10px 10px 0 10px;
  border-radius: 0 0 4px 4px;
  background: $white-0;
  box-shadow: 0 1px 2px 0 rgba(18, 18, 18, 0.2);
}

.versions-dropdown {
  position: relative;
}
