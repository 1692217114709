@import 'src/styles/colors';
@import 'src/styles/elevation';
@import 'src/styles/variables';

.wrapper {
  background: $white-0;
  transition: $transition-duration $transition-timing-function;
  height: 100%;
  min-height: calc(100vh - 166px);
  position: relative;
  left: 0;
}

.sidebar {
  border-right: 1px solid $gray-20;
  padding-top: 55px;
  height: calc(100% - 55px);
  min-height: calc(100vh - 166px);
  position: fixed;
  left: 0;
  width: inherit;
}

.nav-items {
  overflow-y: auto;
  height: inherit;

  &::-webkit-scrollbar {
    display: none;
  }
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: $transition-duration $transition-timing-function;
  cursor: pointer;
  margin: 7px 0;
  text-decoration: none;
  color: $gray-100;
  fill: $gray-100;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    @extend .nav-item;
    color: $active-side-menu-item;
    fill: $active-side-menu-item;
  }

  &--selected {
    @extend .nav-item;
    background: $background-hover;
    border-right: 4px solid $gold;
    color: $active-side-menu-item;
    fill: $active-side-menu-item;
  }

  &--subselected {
    @extend .nav-item;
    color: $active-side-menu-item;
    fill: $active-side-menu-item;

    &--closed {
      @extend .nav-item--subselected;
      background: $background-hover;
      border-right: 4px solid $gold;
    }
  }

  &-title {
    display: flex;
    align-items: flex-start;
    width: 132px;
    opacity: 1;

    &--hidden {
      opacity: 0;
      height: 0;
      overflow: hidden;
    }
  }
}

.title {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 132px;
}

.sidemenus {
  margin-top: -15px;
  margin-bottom: 20px;
}

.submenu-item {
  margin: -10px 0;
  padding: 15px 20px 15px 48px;
  height: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: $transition-duration $transition-timing-function;
  opacity: 1;
  text-decoration: none;
  color: $gray-100;
  fill: $gray-100;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    @extend .submenu-item;
    color: $active-side-menu-item;
    fill: $active-side-menu-item;
  }

  &--hidden {
    display: flex;
    opacity: 0;
    height: 0;
    overflow: hidden;
  }

  &--selected {
    @extend .submenu-item;
    background: $background-hover;
    border-right: 4px solid $gold;
    color: $active-side-menu-item;
    fill: $active-side-menu-item;
  }
}

.button {
  transition: $transition-duration $transition-timing-function;
  background: $white-0;
  border: 1px solid $gray-20;
  border-radius: $border-radius;
  fill: $gray-60;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 16px;
  right: -14px;
  cursor: pointer;

  &:hover {
    border-color: $gray-60;
    fill: $gray-100;
  }
}
