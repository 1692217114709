@import '@componentsStyles/colors';
@import '@componentsStyles/variables';

.alert {
  width: 480px;
  background: $white-0;
  border-radius: $border-radius;
  padding: 40px;
  border: 0;

  &::backdrop {
    background: rgba(18, 18, 18, 0.5);
    animation: show $transition-duration-long $transition-timing-function normal;
  }

  &.hide {
    animation: hide $transition-duration-long $transition-timing-function normal;
    &::backdrop {
      animation: hide $transition-duration-long $transition-timing-function
        normal;
    }
  }
}

dialog[open] {
  animation: show $transition-duration-long $transition-timing-function normal;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.text {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
