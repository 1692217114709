@import '@componentsStyles/colors';

.main {
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: $gray-80;
}
