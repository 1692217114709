@import 'src/styles/colors';
@import 'src/styles/elevation';
@import 'src/styles/variables';

.wrapper {
  width: 830px;
}

.row {
  box-sizing: border-box;
  min-height: 52px;
  padding: 8px 0 8px 0;

  &:nth-child(odd) {
    background: $white-5;
  }

  &:nth-child(even) {
    background: $white-0;
  }

  &:nth-last-child(2) {
    min-height: 64px;
    padding: 14px 0 14px 0;
  }

  &--disabled {
    @extend .row;

    &:nth-child(even) {
      background: $background-hover;
    }
  }
}

.pricing {
  position: relative;
}

.header {
  align-items: center;
  height: 38px;
  display: grid;
  grid-template-columns: 110px 180px 120px 180px 100px;
  margin-right: 20px;
  gap: 30px;

  &-period {
    margin-left: 20px;
    margin-right: -20px;
  }

  &-price {
    text-align: end;
  }
}

.add {
  position: absolute;
  bottom: -12px;
  right: 10px;
}
