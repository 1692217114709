@import '@componentsStyles/colors';
@import '@componentsStyles/elevation';
@import '@componentsStyles/variables';
@import '@componentsStyles/zIndex';

html {
  scroll-behavior: smooth;
}

.button {
  background: $gold;
  border-radius: 48px;
  width: 36px;
  height: 36px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: $transition-duration $transition-timing-function;
  position: fixed;
  bottom: 64px;
  right: 51px;
  opacity: 1;
  visibility: visible;
  z-index: $zIndex-1;

  &--hidden {
    @extend .button;
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    cursor: pointer;
    background: $honey;
  }

  &:focus {
    outline: none;
  }

  &:active {
    background: $gold;
  }
}

.icon {
  path {
    fill: $white-0;
  }
}
