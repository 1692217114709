@import 'src/styles/colors';
@import 'src/styles/zIndex';

.row {
  background: inherit;
}

.cell {
  padding-top: 12px;
  padding-bottom: 13px;
  border-bottom: 1px solid $gray-20;
  vertical-align: top;

  &-name {
    display: flex;
    align-items: center;

    &--sortable {
      @extend .cell-name;
      cursor: pointer;
    }

    &--subtitle {
      flex-direction: column;
    }
  }
}

thead {
  position: sticky;
  top: -1px;
  background-color: $white-0;
  z-index: $zIndex-1;
}

.cell-name:hover {
  .sort--hidden {
    opacity: 1;
    fill: $gray-20;
  }
}

.sort {
  margin-left: 10px;
  fill: $gray-40;
  cursor: pointer;

  &--hidden {
    @extend .sort;
    opacity: 0;
  }
}

.toggleWrapper {
  display: flex;
  align-items: flex-start;

  &--toggle {
    transform: scale(0.5);
    transform-origin: 100% 10%;
  }

  &--info {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
  }

  &--unit {
    font-size: 8px;
    font-weight: 700;
    line-height: 10px;
    margin-top: 2px;
  }
}

.dataTypeBtn {
  &-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  cursor: pointer;
  height: 24px;
  width: 24px;
  border-radius: 2px;
  background: $white-5;
  display: flex;
  align-items: center;
  justify-content: center;

  &-icon {
    fill: $gray-40;

    &:hover {
      fill: $gray-80;
    }
  }

  &-active {
    background-color: rgba(247, 148, 29, 1);

    svg {
      path {
        fill: #fff;
      }
    }
  }
}

.action {
  width: 12px;
  position: relative;
  padding-left: 12px;
}

.actionWrapper {
  display: flex;
  width: 24px;
  margin-left: auto;
  position: relative;
}

.modal {
  position: absolute;
  background: #fff;
  box-shadow:
    -1px -1px 2px rgba(18, 18, 18, 0.05),
    0px 1px 2px rgba(18, 18, 18, 0.2);
  border-radius: 8px;
  right: 27px;
  width: 305px;
  padding: 20px 20px 10px 10px;
}

.modalContent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 0px 10px;
  gap: 40px;
}

.title {
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin: 0 0 20px;
  text-align: left;
  padding: 0;
  color: #8f8f8f;
}

.modalActions {
  padding-left: 10px;
}
