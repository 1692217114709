@import 'src/styles/colors';
@import 'src/styles/variables';

.files {
  margin: 20px 10px 0;
}

.drag-text {
  color: $gray-60;
  max-width: 81px;
}

.form {
  height: 168px;
  width: 100%;
  max-width: 100%;
  text-align: center;
  position: relative;
  background-color: $blue-0;
  border: 1px solid transparent;
  border-radius: $border-radius $border-radius 0 0;
  border-bottom: 1px solid $gray-60;

  &:hover:not(.form--draggable):not(.form--disabled) {
    border-bottom: 1px solid $gray-80;

    .drag-text {
      color: $gray-80;
    }

    .browse-text {
      color: $blue-70;
    }
  }

  &--disabled {
    @extend .form;
    background: $white-5;
    border: 1px solid transparent;
  }

  &--draggable {
    @extend .form;
    background: $blue-5;
    border: 1px dashed $blue-50;
  }
}

.input-file-upload {
  display: none;
}

.label-file-upload {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &:hover:not(.form--draggable):not(.form--disabled) {
    cursor: pointer;
  }
}

.drag-file {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
