@import 'src/styles/colors.scss';
@import 'src/styles/zIndex.scss';
@import 'src/styles/variables.scss';
@import '@components/Typography/Typography.module.scss';

$margin-left: 10px;
$left-offset: 34px;

.form {
  position: relative;
}

.input {
  @extend .typography_body-4;
  -webkit-appearance: none;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  outline: none;
  border: 0;
  border-bottom: 1px solid transparent;
  padding: 8px $left-offset;
  color: $gray-100;
  background: none;
  z-index: $zIndex-1;
  transition: $transition-duration $transition-timing-function;

  &:not([value='']) {
    border-color: $gray-60;
  }

  &::-moz-placeholder {
    @extend .typography_body-3;
    -moz-transition: $transition-duration $transition-timing-function;
    transition: $transition-duration $transition-timing-function;
    color: $gray-60;
  }

  &::placeholder {
    @extend .typography_body-3;
    transition: $transition-duration $transition-timing-function;
    color: $gray-60;
  }

  &:disabled {
    background: $white-5;
    cursor: not-allowed;
    border-radius: $border-radius $border-radius 0 0;
    border-color: transparent;
  }

  &:hover {
    color: $gray-80;
    border-color: $gray-80;
    cursor: pointer;

    &::-moz-placeholder {
      color: $gray-80;
    }

    &::placeholder {
      color: $gray-80;
    }
  }

  &:disabled:hover {
    cursor: not-allowed;
    border-color: transparent;

    &::-moz-placeholder {
      color: $gray-60;
    }

    &::placeholder {
      color: $gray-60;
    }
  }

  &:focus {
    border-bottom: 1px solid $gold;
    z-index: $zIndex-5;

    &::-moz-placeholder {
      opacity: 0;
    }

    &::placeholder {
      opacity: 0;
    }
  }
}

.input:focus ~ .icon-close {
  opacity: 1;
}

.icon {
  position: absolute;
  right: $margin-left;
  bottom: 9px;
  fill: $gray-60;
  z-index: $zIndex-5;
  transition: $transition-duration $transition-timing-function;

  &-search {
    @extend .icon;
    left: $margin-left;
  }

  &-close {
    @extend .icon;
    right: $margin-left;
    opacity: 0;
    cursor: pointer;
  }

  &-loader {
    @extend .icon;
    right: $margin-left;
  }
}

.input:hover:disabled ~ .icon {
  fill: $gray-60;
}

.input:hover ~ .icon {
  fill: $gray-80;
}

.input:focus ~ .icon {
  fill: $gold;
}

/* clears the ‘X’ */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
