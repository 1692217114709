.wrapper {
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    text-wrap: nowrap;
  }

  span {
    font-weight: bold;
  }
}
