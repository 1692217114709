.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 2px;
  padding: 8px 4px;
  transition: background-color 200ms linear;

  > div {
    height: 24px;
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 1);
  }
}
