$lg: 1920px;
$md: 1365px;
$sm: 768px;

$gutter-sm: 8px;
$gutter-md: 20px;
$gutter-lg: 40px;

/* stylelint-disable */
:export {
  lg: $lg;
  md: $md;
  sm: $sm;

  lgNumber: 1920;
  mdNumber: 1365;
  smNumber: 768;
}

$grid-breakpoints: (
  xs: 0,
  sm: $sm,
  md: $md,
  lg: $lg
);

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
// Useful for making responsive utilities.
//    >> breakpoint-infix(xs)
//    ""  (Returns a blank string)
//    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    "-sm"
@function breakpoint-infix($name) {
  @return if(breakpoint-min($name, $grid-breakpoints) == null, '', '-#{$name}');
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($grid-breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name) {
  $min: breakpoint-min($name, $grid-breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}
