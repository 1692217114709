@import 'src/styles/colors';
@import 'src/styles/elevation';
@import 'src/styles/variables';
@import 'src/styles/zIndex';
@import 'src/styles/mixins';

.dropdown {
  width: -moz-fit-content;
  width: fit-content;
  display: inline-flex;
  align-items: center;
  position: relative;
  word-break: initial;

  &:hover {
    cursor: pointer;
  }

  &_disabled {
    color: $gray-20;
    fill: $gray-20;
    cursor: not-allowed;

    &:hover {
      cursor: not-allowed;
    }
  }

  &_list {
    padding-inline-start: 0;
    position: absolute;
    left: 0;
    list-style: none;
    z-index: $dropdown-zIndex;
    overflow-y: auto;
    width: -moz-max-content;
    width: max-content;
    max-width: 300px;
    max-height: 220px;
    background: $white-0;
    @include scrollbar();

    &_top {
      bottom: 7px;
      border-radius: $border-radius $border-radius 0 0;
    }

    &_bottom {
      top: 7px;
      border-radius: 0 0 $border-radius $border-radius;
    }
  }
}

.title {
  margin-right: 6px;
}

.chevron-down {
  transition: $transition-duration $transition-timing-function;
  fill: $gray-20;

  &:hover {
    fill: $gray-100;
  }
  &--underlined {
    fill: $gray-100;
  }
}
