@import 'src/styles/colors';
@import 'src/styles/variables';

.card {
  position: relative;
  color: $gray-100;

  &--disabled {
    position: relative;
    color: $gray-40;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover .add-icon--hover {
    opacity: 1;
  }
}

.dropdown {
  margin-top: 16px;
}

.add-icon {
  position: absolute;
  top: 22px;
  right: 12px;

  &--hover {
    position: absolute;
    top: 18px;
    right: 8px;
    opacity: 0;
  }
}

.card:hover .add-icon--hover {
  opacity: 1;
}
