@import '@componentsStyles/colors.scss';
@import '@componentsStyles/variables.scss';
@import '@componentsStyles/breakpoints.scss';
@import 'src/styles/mixins';

.table {
  &-grid {
    display: grid;
    grid-template-columns: 98px 217px 217px;
    gap: 20px;
    background-color: $white-0;
  }

  &-row {
    @extend .table-grid;

    &:nth-child(odd) {
      .table-cell-price {
        background: $white-5;
      }
    }
  }

  &-cell {
    padding: 9px 10px 25px 10px;
    box-sizing: border-box;

    &-price {
      @extend .table-cell;
      text-align: right;
    }
  }

  &-head {
    @extend .table-grid;

    &-cell {
      border-bottom: 1px solid $gray-20;
      padding: 12px 10px 14px 10px;

      &-right {
        @extend .table-head-cell;
        text-align: right;
      }
    }
  }
}
