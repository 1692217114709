@import 'src/styles/colors';

.container {
  color: $gray-100;
  display: flex;
  align-items: center;
}

.copy {
  margin-left: 8px;
  fill: $gray-100;

  &:hover {
    fill: $gold;
    cursor: pointer;
  }

  &--success {
    @extend .copy;
    fill: $green-70;
    &:hover {
      fill: $green-70;
    }
  }

  &--gold {
    @extend .copy;
    fill: $gold;
    &:hover {
      fill: $gold;
    }
  }

  &--error {
    @extend .copy;
    fill: $red-70;
    &:hover {
      fill: $red-70;
    }
  }
}
