@import 'src/styles/colors';

.row {
  height: 64px;
  position: relative;
}

.icon {
  fill: $gray-20;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 64px;
}
