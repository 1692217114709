@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/elevation';
@import 'src/styles/mixins';
@import '@components/Typography/Typography.module.scss';

table {
  @extend .typography_body-4;
  border-collapse: separate;
  width: 100%;
  border-spacing: 0;
  position: relative;
  background: $background;
}

.table {
  overflow-x: auto;
  overflow-y: scroll;
  background: $background;
  @include scrollbar();
}

.row {
  transition: $transition-duration $transition-timing-function;
  background: $background;

  &:nth-child(2n) {
    background: $white-5;
  }

  &:hover {
    background: $background-hover;

    .row-menu {
      div {
        path {
          fill: $gray-100;
        }
      }

      ul {
        path {
          fill: inherit;
        }
      }
    }
  }

  &-clickable {
    cursor: pointer;
  }

  &-selected {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 3px;
      width: 4px;
      height: 91%;
      background-color: $gold;
    }
  }

  .firstSelectedColumn {
    color: $gold;
    font-weight: 600;
    position: relative;

    &-hovered {
      &::after {
        content: '';
        position: absolute;
        top: calc(50% - 8px);
        right: 0;
        width: 16px;
        height: 16px;
        background: url('@assets/images/arrow-right.svg') no-repeat bottom;
      }
    }
  }

  .selectedCell {
    & > div > span {
      padding: 1px 8px;
      color: $white-0;
      border-radius: 20px;
      width: -moz-max-content;
      width: max-content;
    }

    & > div > span:nth-child(1) {
      background-color: $blue-20;
    }

    & > div > span:nth-child(2) {
      background-color: $orange-20;
    }

    & > div > span:nth-child(3) {
      background-color: $green-20;
    }

    & > div > span:nth-child(4) {
      background-color: $purple-20;
    }

    & > span {
      padding: 1px 8px;
      color: $white-0;
      border-radius: 20px;
      font-weight: 700;
    }

    & > span:nth-child(1) {
      background-color: $blue-20;
    }

    & > span:nth-child(2) {
      background-color: $orange-20;
    }

    & > span:nth-child(3) {
      background-color: $green-20;
    }

    & > span:nth-child(4) {
      background-color: $purple-20;
    }
  }

  .selectedRowCells {
    & > span {
      font-weight: 700;
    }

    & > div > span {
      font-weight: 700;
    }
  }
}

.loaderContainer {
  padding: 8px 0;
}

.noMoreItemsContainer {
  padding: 20px 0;
}

.defaultCursor {
  label {
    span {
      cursor: default;
    }
  }
}

.plusButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 24px;
  height: 24px;
  background-color: #d8d8d8;
  border-radius: 2px;

  &:hover {
    background-color: #f7941d;
  }
}
