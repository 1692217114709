@import 'src/styles/colors';

.chevron {
  border-radius: 2px;
  color: $white-0;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  word-break: initial;
}

.title {
  padding: 2px 6px;
  white-space: nowrap;
}
