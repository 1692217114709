@import '@componentsStyles/colors.scss';
@import '@componentsStyles/variables.scss';
@import '@componentsStyles/breakpoints.scss';
@import 'src/styles/mixins';

.modal {
  width: 80vw;
  max-height: calc(100vh - 160px);
  background: $white-0;
  border-radius: $border-radius;
  padding: 20px 40px;
  border: 0;
  @include scrollbar();
  overflow-x: auto;
  overflow-y: auto;
  box-sizing: border-box;

  &::backdrop {
    background: rgba(18, 18, 18, 0.5);
    animation: show $transition-duration-long $transition-timing-function normal;
  }

  &.hide {
    animation: hide $transition-duration-long $transition-timing-function normal;
    &::backdrop {
      animation: hide $transition-duration-long $transition-timing-function
        normal;
    }
  }
}

dialog[open] {
  animation: show $transition-duration-long $transition-timing-function normal;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.text {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  &:hover {
    cursor: pointer;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
