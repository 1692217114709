@import '@componentsStyles/colors.scss';
@import '@componentsStyles/elevation.scss';
@import '@componentsStyles/variables.scss';
@import '@componentsStyles/breakpoints.scss';
@import '@componentsStyles/mixins.scss';

.header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  transition: $transition-duration $transition-timing-function;
}

.grid {
  display: grid;
  grid-template-columns: 100px 100px 1fr;
  gap: 20px;
}

@container (min-width: 500px) {
  .grid {
    grid-template-columns: 1fr 100px 2fr;
    gap: 40px;
  }
}

@container (min-width: 600px) {
  .grid {
    grid-template-columns: 2fr 100px 3fr;
    gap: 60px;
  }
}

@container (min-width: 800px) {
  .grid {
    grid-template-columns: 2fr 1fr 2fr;
    gap: 80px;
  }
}

.details {
  position: absolute;
  top: 24px;
  right: 12px;

  path {
    transition: $transition-duration $transition-timing-function;
    fill: $gray-40;
  }
}

.card {
  position: relative;
  padding: 20px 40px 0 40px;

  &:hover {
    cursor: pointer;
    .header {
      color: $active-side-menu-item;
    }
    path {
      fill: $gray-100;
    }
  }

  &--loading {
    position: relative;
    padding: 20px 40px 0 40px;

    path {
      fill: $gray-20;
    }
  }
}

.scroll-container {
  width: inherit;
  overflow-x: auto;
  padding-bottom: 20px;
  container-type: inline-size;
  @include scrollbar-transparent();
}

.min-width {
  min-width: -moz-max-content;
  min-width: max-content;
}
