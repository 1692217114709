@import 'src/styles/colors';
@import 'src/styles/elevation';
@import 'src/styles/variables';

.card {
  padding: 20px 40px;
  background: $white-5;
  border-radius: $border-radius;
  color: $gray-100;

  &--disabled {
    @extend .card;
    color: $gray-40;
  }
}
