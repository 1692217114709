@import '@componentsStyles/colors.scss';
@import '@componentsStyles/zIndex.scss';
@import '@componentsStyles/variables.scss';
@import '@componentsStyles/elevation.scss';
@import '@componentsStyles/mixins.scss';
@import '@components/Typography/Typography.module.scss';

$margin-left: 10px;
$margin-s: 4px;

.form {
  position: relative;
}

.wrapper {
  position: relative;
  padding-bottom: 20px;

  &--disabled {
    position: relative;
    padding-bottom: 20px;
  }
}

.input {
  @extend .typography_label-1;
  -webkit-appearance: none;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  outline: none;
  border: 0;
  border-bottom: 1px solid $gray-60;
  padding: 46px 0 $margin-left $margin-left;
  color: $gray-100;
  background: none;
  z-index: $zIndex-1;
  transition: $transition-duration $transition-timing-function;

  &:hover:not(.input--disabled):not(.input_error) {
    border-color: $gray-80;
    cursor: pointer;

    + .label {
      color: $gray-80;
    }

    ~ .icon {
      fill: $gray-80;
    }
  }

  &:focus:not(.input--disabled):not(.input_error) {
    border-bottom: 1px solid $gold;
    z-index: $zIndex-5;

    + .label {
      color: $gold;
    }

    ~ .icon {
      fill: $gold;
    }
  }

  &_error {
    border-bottom: 1px solid $red-70;

    + .label {
      color: $red-70;
    }

    ~ .icon {
      fill: $red-70;
    }

    &:hover,
    &:focus {
      border-bottom: 1px solid $red-70;
    }
  }

  &--disabled {
    @extend .input;
    background: $white-5;
    cursor: not-allowed;
    border-radius: 4px 4px 0 0;
    z-index: 0;
    border-color: transparent;

    &:hover,
    &:focus {
      cursor: not-allowed;
    }
  }
}

.values {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -14px;
  margin-top: -22px;
  margin-right: 22px;
  min-height: 28px;
  gap: 8px;
}

.label {
  @extend .typography_label-1;
  position: absolute;
  top: 19px;
  transition: $transition-duration $transition-timing-function;
  left: 0;
  color: $gray-60;
  padding: $margin-s $margin-s $margin-s $margin-left;

  &--up {
    @extend .typography_label-2;
    @extend .label;
    top: 0;
    opacity: 1;
  }
}

.required-symbol {
  color: $red-70;
  margin-left: $margin-s;
}

.error-message {
  position: absolute;
  margin-top: $margin-s;
  margin-left: $margin-left;
  display: block;
}

.icon {
  position: absolute;
  right: $margin-left;
  top: 24px;
  fill: $gray-60;
  z-index: $zIndex-5;
  transition: $transition-duration $transition-timing-function;
}

.list {
  position: absolute;
  background: $white-0;
  width: 100%;
  z-index: $dropdown-zIndex;

  @include scrollbar();
  overflow-y: auto;
  min-height: 40px;
  max-height: 260px;
  transition: $transition-duration $transition-timing-function opacity;

  &--top {
    @extend .list;
    @include elevation(topSelectList);
    bottom: 77px;
    border-radius: $border-radius $border-radius 0 0;
  }

  &--bottom {
    @extend .list;
    @include elevation(SelectList);
    border-radius: 0 0 $border-radius $border-radius;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
  }

  &-item {
    @extend .typography_label-1;
    padding: 10px 20px;
    transition: $transition-duration $transition-timing-function;
    display: flex;
  }
}

.loader {
  padding: 28px 14px 32px 20px;
  display: flex;
  justify-content: flex-end;
}

.select-all {
  padding: 20px;
  display: flex;
  color: $gray-60;

  &-checkbox {
    margin-right: 8px;
    span {
      border-color: $gray-60;
    }
  }
}
