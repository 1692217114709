@import '@componentsStyles/colors.scss';
@import '@componentsStyles/zIndex.scss';
@import '@componentsStyles/variables.scss';
@import '@componentsStyles/elevation.scss';
@import '@componentsStyles/mixins.scss';
@import '@components/Typography/Typography.module.scss';

.value {
  opacity: 1;
  display: flex;
  align-items: center;
  transition: $transition-duration $transition-timing-function;
  border-radius: 16px;
  padding: 2px 8px;
  font-size: 14px !important;
  width: -moz-max-content;
  width: max-content;

  &:hover {
    cursor: pointer;
  }

  &:hover .close {
    opacity: 1;
  }

  &--disabled {
    color: #cbcbcb !important;
    background-color: #f6f6f6 !important;
  }

  &--hidden {
    @extend .value;
    opacity: 0;
  }
}

.close {
  transition: $transition-duration $transition-timing-function;
  margin-left: 8px;
  z-index: $zIndex-1;
  cursor: pointer;
  opacity: 0.5;

  &--disabled {
    margin-left: 8px;
    path {
      fill: #cbcbcb !important;
    }
  }
}
