@import 'src/styles/colors';
@import 'src/styles/elevation';
@import 'src/styles/variables';

.row {
  box-sizing: border-box;
  min-height: 52px;
  padding: 8px 10px 8px 10px;

  &:nth-child(odd) {
    background: $white-5;
  }
  &:nth-child(even) {
    background: $white-0;
  }

  &:nth-last-child(2) {
    min-height: 64px;
    padding: 14px 10px 14px 10px;
  }

  &--disabled {
    @extend .row;
    &:nth-child(even) {
      background: $background-hover;
    }
  }
}

.add {
  position: absolute;
  bottom: -12px;
  right: 10px;
}

.pricing {
  position: relative;
}

.more-users {
  position: relative;
  padding: 8px 10px 8px 10px;
  min-height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
  }

  &--disabled {
    @extend .more-users;
    &:hover {
      cursor: default;
    }
  }
}

.percent {
  margin-top: 2px;
  text-align: right;
  color: $gray-60;
}

.preview {
  display: flex;
  justify-content: space-between;
  margin-top: 65px;
}
