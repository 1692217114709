@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/elevation';
@import 'src/styles/zIndex';

.icon {
  fill: $gray-20;
  cursor: pointer;
  padding-bottom: 0 !important;
  padding-top: 0 !important;

  &-block {
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: 24px;
    margin-left: auto;

    &:hover {
      > .menu-wrap {
        display: block;
      }
    }
  }
}

.menu-wrap {
  position: absolute;
  padding-bottom: 20px;
  right: 18px;
  top: 44px;
  display: none;
  z-index: $zIndex-1;

  &:hover {
    display: block;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  background: $white-0;
  margin-block-start: 0;
  padding-inline-start: 0;
  @include elevation(menu);
  padding: 20px;
  margin: 0;

  &--top {
    @extend .menu;
    border-radius: 0 0 $border-radius $border-radius;
  }

  &--bottom {
    @extend .menu;
    border-radius: $border-radius $border-radius 0 0;
  }

  &-item {
    transition-property: color, fill;
    transition-duration: $transition-duration;
    transition-timing-function: $transition-timing-function;
    display: flex;
    align-items: center;
    padding: 20px;
    flex-grow: 1;

    &--disabled {
      @extend .menu-item;
      cursor: not-allowed;
      color: $gray-20;
      fill: $gray-20;
    }

    &:hover:not(.menu-item--disabled) {
      color: $active-side-menu-item;
      fill: $active-side-menu-item;
      cursor: pointer;
    }
  }
}
