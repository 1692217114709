@import '@xq/ui-kit/lib/styles/breakpoints.scss';

.contacts {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;

  @media (min-width: $sm) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: $md) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }

  @media (min-width: $lg) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
  }
}
