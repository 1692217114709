@import '@componentsStyles/colors';
@import '@componentsStyles/elevation';
@import '@componentsStyles/variables';

.button {
  position: relative;
  padding: 12px 36px;
  cursor: pointer;
  border-radius: $border-radius;
  border: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &_primary {
    color: $white-0;
    background: $btn-primary-active;
    @include elevation(button);

    &:hover {
      background: $strawberry;
    }

    &:active {
      background: $red;
    }

    &:disabled {
      color: $gray-60;
      background: $white-5;
    }
  }

  &_secondary {
    color: $gray-100;
    background-color: $white-0;
    border: 1px $gray-60 solid;

    &:hover {
      border-color: $gray-100;
    }

    &:active {
      color: $gold;
      border-color: $gold;
    }

    &:disabled {
      color: $gray-20;
      border-color: $gray-20;
    }

    &--loading {
      border-color: $gold;
    }
  }

  &_inactive {
    color: $gray-60;
    background-color: $white-5;
  }

  &_third {
    color: $white-0;
    background-color: $gold;
    padding: 8px 16px;

    svg {
      fill: $white-0;
    }

    &:hover {
      background-color: $honey;
    }

    &:active {
      background-color: $gold;
    }

    &:disabled {
      color: $gray-60;
      background-color: $white-5;

      svg {
        fill: $gray-60;
      }
    }
  }

  &_fourth {
    color: $gray-100;
    padding: 0;
    background: transparent;

    &:active {
      color: $gold;
      border-color: $gold;

      svg {
        fill: $gold;
      }
    }

    &:disabled {
      color: $gray-20;
      border-color: $gray-20;

      svg {
        fill: $gray-20;
      }
    }
  }

  &_fifth {
    color: $white-0;
    background-color: $gold;
    padding: 4px;

    svg {
      fill: $white-0;
    }

    &:hover {
      background-color: $honey;
    }

    &:active {
      background-color: $gold;
    }

    &:disabled {
      color: $gray-60;
      background-color: $white-5;

      svg {
        fill: $gray-60;
      }
    }
  }
}

.loader {
  position: absolute;
  width: 16px;
  height: 16px;
}

.text {
  display: flex;
  align-items: center;

  &--hidden {
    visibility: 'hidden';
  }
}
