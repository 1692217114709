$offsets: (
  0: 0px,
  2: 2px,
  4: 4px,
  6: 6px,
  8: 8px,
  10: 10px,
  12: 12px,
  16: 16px,
  18: 18px,
  20: 20px,
  24: 24px,
  30: 30px,
  32: 32px,
  40: 40px,
  60: 60px,
  80: 80px
);

@mixin make-offset($name, $property) {
  @each $offset, $value in $offsets {
    .#{$name} {
      &-#{$offset} {
        #{$property}: $value;
      }
      &r-#{$offset} {
        #{$property}-right: $value;
      }
      &l-#{$offset} {
        #{$property}-left: $value;
      }
      &t-#{$offset} {
        #{$property}-top: $value;
      }
      &b-#{$offset} {
        #{$property}-bottom: $value;
      }
      &x-#{$offset} {
        #{$property}-right: $value;
        #{$property}-left: $value;
      }
      &y-#{$offset} {
        #{$property}-top: $value;
        #{$property}-bottom: $value;
      }
    }
  }
}

@include make-offset('m', 'margin');
@include make-offset('p', 'padding');
