@import '@componentsStyles/colors.scss';
@import '@componentsStyles/zIndex.scss';
@import '@componentsStyles/elevation.scss';
@import '@componentsStyles/mixins.scss';
@import 'src/styles/variables';

$max-width: 572px;

.wrap {
  width: $max-width;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.title {
  display: flex;
  gap: 2px;
  align-items: center;
  cursor: pointer;
}

.selections {
  display: flex;
  justify-content: center;
  gap: 20px;
}
