.flex {
  &-row {
    flex-direction: row;
  }

  &-col {
    flex-direction: column;
  }
}

.justify-content {
  &-start {
    justify-content: start;
  }

  &-end {
    justify-content: end;
  }

  &-center {
    justify-content: center;
  }

  &-between {
    justify-content: space-between;
  }

  &-around {
    justify-content: space-around;
  }

  &-evenly {
    justify-content: space-evenly;
  }
}

.align-items {
  &-start {
    align-items: start;
  }

  &-end {
    align-items: end;
  }

  &-center {
    align-items: center;
  }

  &-baseline {
    align-items: baseline;
  }

  &-stretch {
    align-items: stretch;
  }
}

.align-self {
  &-start {
    align-self: start;
  }

  &-end {
    align-self: end;
  }

  &-center {
    align-self: center;
  }

  &-baseline {
    align-self: baseline;
  }

  &-stretch {
    align-self: stretch;
  }
}
