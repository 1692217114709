@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/zIndex';
@import '@components/Typography/Typography.module.scss';

.wrapper {
  position: relative;
  transition: $transition-duration $transition-timing-function;
}

.avatar {
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  &--name {
    background-color: $background-hover;
  }

  &--small {
    width: 40px;
    height: 40px;
  }

  &--medium {
    width: 60px;
    height: 60px;
  }

  &--large {
    width: 100px;
    height: 100px;
  }
}

.letters {
  color: $gray-80;
  font-family: 'Proxima Nova', sans-serif;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;

  &--small {
    @extend .letters;
    font-size: 16px;
    line-height: 22px;
  }

  &--medium {
    @extend .letters;
    font-size: 24px;
    line-height: 22px;
  }

  &--large {
    @extend .letters;
    font-size: 36px;
    line-height: 40px;
  }
}

.icon {
  bottom: 0;
  display: flex;
  justify-content: center;
  color: $white-0;
  background: transparent;
  cursor: pointer;

  &--small {
    @extend .icon;
    width: 40px;
    margin-bottom: -0.4px;

    svg {
      height: 6.4px;
      width: 6.4px;
    }
  }

  &--medium {
    @extend .icon;
    width: 60px;
    margin-bottom: -0.5px;
    svg {
      height: 9.6px;
      width: 9.6px;
    }
  }

  &--large {
    @extend .icon;
    width: 100px;
    margin-bottom: -0.8px;
    svg {
      height: 16px;
      width: 16px;
    }
  }
}

.hover {
  position: absolute;
  bottom: 0;
  left: 0;
  transition: opacity $transition-duration $transition-timing-function;
  opacity: 0;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &--small {
    @extend .hover;
    width: 40px;
  }

  &--medium {
    @extend .hover;
    width: 60px;
  }

  &--large {
    @extend .hover;
    width: 100px;
  }
}

.input:hover ~ .hover {
  opacity: 1;
  cursor: pointer;
}

.input {
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: $zIndex-1;
  border-radius: 0 0 100px 100px;
  cursor: pointer;

  &--small {
    @extend .input;
    width: 40px;
    height: 20px;
  }

  &--medium {
    @extend .input;
    width: 60px;
    height: 30px;
  }

  &--large {
    @extend .input;
    width: 100px;
    height: 50px;
  }
}

.click-zone {
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0 0 100px 100px;
}
