.image {
  display: block;

  &_default {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }

  &_fullscreen {
    width: 100vw;
    height: 100vh;
    -o-object-fit: cover;
       object-fit: cover;
  }

  &_halfscreen {
    width: 50vw;
    height: 100vh;
    -o-object-fit: cover;
       object-fit: cover;
  }

  &_contain {
    -o-object-fit: contain;
       object-fit: contain;
  }
}
