@import '@xq/ui-kit/lib/styles/breakpoints.scss';

.main {
  display: grid;
  grid-template-columns: 9fr 1fr;
  grid-template-areas:
    'form .'
    'license .'
    'buttons .';

  @media (min-width: $sm) {
    grid-template-columns: 6fr 4fr;
    grid-template-areas:
      'form .'
      'license .'
      'buttons .';
  }

  @media (min-width: $md) {
    grid-template-columns: 3fr 1fr 3fr 3fr;
    grid-template-areas:
      'form . license .'
      'buttons . license .'
      '.  . license .'
      '. . license .';
  }
}

.buttons {
  grid-area: buttons;
}

.form {
  grid-area: form;
}

.license {
  grid-area: license;
}

.user-info {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
