@import 'src/styles/colors';
@import 'src/styles/elevation';
@import 'src/styles/variables';
@import 'src/styles/zIndex';

.item {
  padding: 10px 20px;

  &:hover {
    color: $gold;
  }
}
