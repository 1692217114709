@import 'src/styles/colors';
@import 'src/styles/elevation';
@import 'src/styles/variables';
@import 'src/styles/zIndex';
@import 'src/styles/mixins';
@import '@components/Typography/Typography.module.scss';

.breadcrumbs {
  display: flex;
  align-items: center;

  &--disabled {
    display: flex;
    align-items: center;
  }
}

.title {
  transition: $transition-duration $transition-timing-function;
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: none;

  &--dropdown {
    @extend .title;
  }

  &-wrapper {
    display: flex;
    align-items: center;
  }
}

.next {
  margin: 0 12px;

  path {
    fill: $gray-20;
  }
}

.dropdown {
  position: absolute;
  top: 26px;
  height: auto;
  z-index: $dropdown-zIndex;
  background-color: $white-0;
  border-radius: 0 0 4px 4px;
  max-height: calc(180px + 36px); // 36px is the height of search + margin

  &-icon {
    margin-left: 6px;

    path {
      transition: $transition-duration $transition-timing-function;
    }
  }

  &-list {
    @include elevation(SelectList);
    @include scrollbar();
    overflow-x: hidden;
    overflow-y: auto;
    width: -moz-max-content;
    width: max-content;
    max-height: 500px;
    display: inline-block;
    background: $white-0;
    border-radius: 0 0 4px 4px;

    &-secondary {
      @extend .dropdown-list;
      position: absolute;
      left: 100%;
    }
  }

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;

    &-secondary {
      @extend .dropdown-wrapper;
    }

    &-icon {
      margin-left: 10px;

      path {
        transition: $transition-duration $transition-timing-function;
      }
    }

    &-title {
      padding: 20px 10px 10px;
    }

    &-item {
      color: $gray-100;
      transition: $transition-duration $transition-timing-function;
      display: flex;
      align-items: center;
      min-width: -moz-max-content;
      min-width: max-content;

      &-secondary {
        color: $gray-100;
        transition: $transition-duration $transition-timing-function;
        display: flex;
        align-items: center;
        min-width: -moz-max-content;
        min-width: max-content;

        &:hover {
          color: $gold;
        }
      }
    }

    &:hover {
      .dropdown-wrapper-item {
        color: $gold;
      }

      path {
        fill: $gold;
      }
    }

    &--active {
      .dropdown-wrapper-item {
        color: $gold;
      }

      path {
        fill: $gold;
      }
    }

    &--disabled {
      cursor: default;

      .dropdown-wrapper-item {
        color: $gray-40;
        cursor: default;
      }

      path {
        fill: $gray-40;
      }

      &:hover {
        .dropdown-wrapper-item {
          color: $gray-40;
        }

        path {
          fill: $gray-40;
        }
      }
    }
  }

  &-no-items {
    padding: 10px 20px;
    color: $gray-100;
  }
}

.search {
  @extend .typography_body-6;
  width: 100%;
  outline: none;
  border: none;
  color: $gray-100;

  &::-moz-placeholder {
    color: $gray-60;
  }

  &::placeholder {
    color: $gray-60;
  }

  &-block {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
  }

  &-close {
    cursor: pointer;

    path {
      color: $gray-60;
    }
  }

  &:-moz-placeholder-shown + .search-close {
    display: none;
  }

  &:placeholder-shown + .search-close {
    display: none;
  }
}

.breadcrumbs {
  .title {
    cursor: pointer;
    color: $gray-60;

    &:hover {
      color: $gray-100;
      text-decoration-line: underline;
      text-decoration-thickness: 0.5px;
      text-underline-offset: 2px;

      .dropdown-icon {
        path {
          fill: $gray-100;
        }
      }
    }

    &--dropdown {
      cursor: pointer;
      color: $gray-100;

      .dropdown-icon {
        path {
          fill: $gray-100;
        }
      }

      &:hover {
        color: $gray-80;
        text-decoration-line: none;
      }
    }
  }

  .dropdown {
    &-icon {
      cursor: pointer;

      path {
        fill: $gray-60;
      }
    }
  }
}

.breadcrumbs--disabled {
  .title {
    color: $gray-20;
  }

  .dropdown {
    &-icon {
      path {
        fill: $gray-20;
      }
    }
  }
}

.link {
  text-decoration: none;
  display: block;
}
