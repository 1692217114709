@import '@componentsStyles/colors';
@import '@componentsStyles/variables';

/* Sizes */
$checkbox-width: 16px;
$checkbox-height: 16px;
$checkbox-tick-width: 4px;
$checkbox-tick-height: 8px;

.box {
  position: relative;
  display: inline-block;
  width: $checkbox-width;
  height: $checkbox-height;
  overflow: hidden;

  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  box-sizing: border-box;
  border: 1px solid $gray-100;
  border-radius: $border-radius;
  margin-top: 2px;
}

.checkbox {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: 0;
  text-transform: inherit;
  margin-bottom: 0;
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-content: center;

  &:focus {
    .box {
      border-color: $gold;
    }

    .input:checked + .box {
      border-color: $gold;
    }
  }
}

.input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);

  /* Checked */
  &:checked + span {
    background-color: $gold;
    border-color: $gold;
  }

  &:checked:disabled + span {
    background-color: $gray-20;
    border-color: $gray-20;
  }

  &:checked + span::after {
    content: '';
    display: block;
    position: absolute;
    left: 4px;
    top: 0;
    width: $checkbox-tick-width;
    height: $checkbox-tick-height;
    border: solid $white-0;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  /* Disabled */
  &:disabled ~ span {
    cursor: not-allowed;
    border-color: $gray-20;
    color: $gray-20;
  }

  &:checked:disabled ~ span {
    cursor: not-allowed;
    border-color: $gray-20;
    color: $gray-100;
  }
}

.text {
  margin-left: $checkbox-tick-height;
  vertical-align: top;
}
