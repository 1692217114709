.lowercase {
  &::first-letter {
    text-transform: lowercase;
  }
}

.uppercase {
  &::first-letter {
    text-transform: uppercase;
  }
}

.no-text-transform {
  &::first-letter {
    text-transform: none;
  }
}

.container-queries-wrap {
  container-type: inline-size;
  z-index: 2;
}

.table-filters-wrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  gap: 20px;
}

.table-search {
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;
  max-width: 335px;
  min-width: 200px;
}

@container (min-width: 800px) {
  .table-filters-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.table-filter {
  min-width: 100%;
}

@container (min-width: 800px) {
  .table-filter {
    min-width: auto;
  }
}
