@import '@componentsStyles/colors.scss';
.divider {
  margin: 0;
  border: 0;
  &--thin {
    @extend .divider;
    border-bottom: 0.3px solid $gray-100;
  }

  &--bold {
    @extend .divider;
    border-bottom: 1px solid $gray-60;
  }
}
