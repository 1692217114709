@import '@componentsStyles/colors';
@import '@componentsStyles/variables';

.group {
  display: flex;

  &-horizontal {
    @extend .group;
    align-items: center;
    flex-direction: row;
  }

  &-vertical {
    @extend .group;
    flex-direction: column;
  }
}

.container {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  transition: $transition-duration $transition-timing-function;

  &:hover {
    input ~ .checkmark {
      border-color: $gold;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkmark {
      background-color: $gold;
      border-color: $gold;
    }

    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  &--disabled {
    @extend .container;
    cursor: default;

    &:hover {
      input ~ .checkmark {
        border-color: $gray-20;
      }
    }

    input {
      cursor: default;

      &:checked ~ .checkmark {
        background-color: $gray-20;
        border-color: $gray-20;
      }
    }

    .checkmark {
      border-color: $gray-20;
    }
  }

  &-horizontal {
    margin-right: 40px;
  }

  &-vertical {
    padding: 10px 0;

    &:first-child {
      padding-top: 0;
    }
  }
}

.checkmark {
  position: relative;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 1px solid $gray-100;
  border-radius: 50%;
  transition: $transition-duration $transition-timing-function;

  &:after {
    content: '';
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: $white-0;
  }
}
