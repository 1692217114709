@import '@xq/ui-kit/lib/styles/breakpoints.scss';

.grid {
  display: grid;
  gap: 8px;
  margin-bottom: 8px;

  @media (min-width: $md) {
    gap: 20px;
    margin-bottom: 20px;
  }

  @media (min-width: $lg) {
    gap: 40px;
    margin-bottom: 40px;
  }

  &-1 {
    @extend .grid;
    position: relative;
  }

  &-2 {
    @extend .grid;
    grid-template-areas:
      'info'
      'users';
    @media (min-width: $sm) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: 'info users';
    }
    @media (min-width: $md) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: 'info users';
    }
  }

  &-3 {
    @extend .grid;
    grid-template-columns: 1fr;
    @media (min-width: $sm) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: $md) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.info {
  grid-area: info;
}

.users {
  grid-area: users;
}
