@import 'src/styles/colors';
@import 'src/styles/elevation';
@import 'src/styles/variables';

.header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  transition: $transition-duration $transition-timing-function;
}

.row {
  display: flex;
  margin-top: 8px;
}

.details {
  position: absolute;
  top: 24px;
  right: 12px;
}

.card {
  position: relative;
  &:hover {
    cursor: pointer;
    .header {
      color: $active-side-menu-item;
    }
    .redirect {
      path {
        fill: $gray-100;
      }
    }
  }

  &--loading {
    position: relative;
    path {
      fill: $gray-20;
    }
  }
}

.edit-icon {
  cursor: pointer;
  &:hover {
    path {
      fill: $gray-100;
    }
  }
}

.details {
  path {
    transition: $transition-duration $transition-timing-function;
    fill: $gray-40;
  }
}
